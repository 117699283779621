import React, {  useEffect, useState, useCallback  } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { store } from "../../actions/dashboard";
import api from '../../common/api';
import ctabg from '../../assets/img/lines/16-old.png'
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

const Content =(props)=> {
    var pageUrl = window.location.href;
    var pageTitle = "Home Page";
    var page_request_details = {
        'pageTitle' : pageTitle,
        'pageUrl' : pageUrl
    }
    const SITE_KEY = "6Lf2ME4jAAAAAOUILuUZwmGbnF71_DM2Jk1pCA2i";
    const [frmData, setFrmData] = useState({
        name: "",
        mobile: "",
        email:"",
        message:"",
        page_request_details:JSON.stringify(page_request_details),
        inquiry_from:pageTitle,
        resume:"",
        reCaptcha_token:"",
    });
    const [errors,setError] = useState({});
    const onChangeHandler =(event) =>{
        const {name,value}  =event.target;
        setFrmData((preValue)=>{
            return {
                ...preValue,
                [name]:value,
            };
        });
        setError((preValue)=>{
            return {
                ...preValue,
                [name]:"",
            };
        });
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
              setFrmData((preValue)=>{
                  return {
                      ...preValue,
                      'reCaptcha_token':token,
                  };
              });
            });
        });
    }
    const handleValidSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        if(!frmData.name){
            errors["name"] = "Please enter your name.";
        }
       
        if(!frmData.email){
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(frmData.email)) {
            formIsValid = false;
            errors["email"] = 'Invalid email address.'
        }
        if(!frmData.mobile){
            formIsValid = false;
            errors["mobile"] = "Please enter your Mobile no.";
        }
        if(!frmData.message){
            formIsValid = false;
            errors["message"] = "Please enter your Message.";
        }
        setError(errors);
        if(formIsValid==true){
           props.store(frmData,api.CONTACT);
        }
    }
    return (
            <section className="contact-section contact-page section-gap bg-img-c send-message-contact-form" style={{ backgroundImage: "url(" + ctabg + ")" }}>
                <div className="container">
                    <div className="contact-form">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-10">
                                <div className="section-title text-center mb-40">
                                    <h2 className="title">Unlock Your Business Potential Today!</h2>
                                </div>
                                <form onSubmit={handleValidSubmit}>
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Full Name" name="name" value={frmData.name} onChange={onChangeHandler}/>
                                                <span className="icon"><i className="far fa-user-circle" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["name"]}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="email" placeholder="Your Email Address" name="email" value={frmData.email} onChange={onChangeHandler} />
                                                <span className="icon"><i className="far fa-envelope-open" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["email"]}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Phone" name="mobile" value={frmData.mobile} onChange={onChangeHandler} />
                                                <span className="icon"><i className="fa fa-phone" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["mobile"]}</span>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea">
                                                <textarea placeholder="Write Message" name="message" value={frmData.message} onChange={onChangeHandler} />
                                                <span className="icon"><i className="far fa-pencil" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["message"]}</span>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" data-event="send-contact" className="main-btn main-btn-3 main-btn-4">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    
}
const mapStateToProps = (state) =>
{
    const { url, singledata, error_msg, updateData, listData } = state.dashboards;
    return { url, singledata, error_msg, updateData, listData };
};

export default connect(mapStateToProps, { store })(Content);

