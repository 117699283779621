import React, { Component, Fragment } from 'react'
import Cta from './Cta'
import Jobdetails from './Jobdetails'
import Banner from './Banner'

class Content extends Component {
    
    render() {
        return (
            <Fragment>
                {/* <Banner {...this.props}/> */}
                <Jobdetails {...this.props}/>
            </Fragment>
        );
    }
}

export default Content;