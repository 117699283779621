import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import b2c from '../../../assets/img/services/eCommerceDevelopment/b2c.svg'
import b2b from '../../../assets/img/services/eCommerceDevelopment/b2b.svg'
import onlinemarket from '../../../assets/img/services/eCommerceDevelopment/onlinemarket.svg'
import webportal from '../../../assets/img/services/eCommerceDevelopment/webportal.svg'

const serviceblock = [
    { icon: b2c, title: 'B2C eCommerce', text: 'We can build multi-lingual and multi-purpose B2C eCommerce solutions for your business. We create platforms that provide optimal performance leading to better sales and customer-client relationships. Our eCommerce solutions experts build and manage intelligent B2C eCommerce platforms integrated with responsive design and functionality.' },
    { icon: b2b, title: 'B2B eCommerce', text: 'We bring personalization into your B2B solutions building new experiences for your customers. We create platforms that have business-friendly features and make associating with your clients easier. With in-built features like inventory management, ERP, and chatbots, businesses can find easy access to smart business solutions and management.' },
    { icon: onlinemarket, title: 'Online Marketplaces', text: 'Our eCommerce experts have the expertise to build online marketplaces that are customized to suit your business needs. We create fully-featured online marketplaces to deliver quick services to your customers. We create solutions that are easier to manage, understand, and operate for your executives and customers.' },
    { icon: webportal, title: 'Web Portals', text: 'We design, engineer, and create web portals that can be integrated into your business process. Our in-house development team can create portals for every type of business activity and bring efficiency into the operations and systems. Be it vendor management, customer engagement, inventory management, etc.; we can build the best-fit solution for your business.' },
]

function Service(props) {
    return (
        <section className="service-section shape-style-one section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Delivering Possibilities with Intelligent eCommerce</span>
				<h2 className="title">Begin your Online Journey with Smart eCommerce Platforms Leading to Better Brand Awareness and Visibility</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center">
                                <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                {/* <a href='javascript:void(0)' className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                        <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
            <div className="dots-line">
                <img src={lines} alt="" />
            </div>
        </section>
    );
}

export default Service;