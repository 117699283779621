import React, { Component, Fragment } from 'react'
import Advancedtab from '../../layouts/technologies'
import Cta from './Cta'
import Service from './Service'
import ServiceOld from './Service-old'
import Video from './Video'
import Workingprocess from './Workingprocess'
import Banner from './Banner'
import Contact from './Contact'
import WhyWeBest from './WhyWeBest'
import Testimonials from './Testimonials'
import Faqs from './Faqs'
import Client from '../../layouts/Client'
import Project from './Project'
import schema from "./schema.json";

class Content extends Component {
    render() {

        return (
            <Fragment>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
                <Banner />
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap line-bottom">
                            <Client />
                        </div>
                    </div>
                </section>
                <Service />
                {/* <Project /> */}
                <Contact />
                <Workingprocess />
                <Testimonials />
                <WhyWeBest />
                <Faqs />
                <Cta />
            </Fragment>
        );
    }
}

export default Content;