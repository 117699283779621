import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import illustration from '../../../assets/img/services/webDevelopment/faq.jpg'
import circleimg from '../../../assets/img/services/webDevelopment/faq2.JPG'
import $ from 'jquery';

function Faqs(props) {

    return (
        <section className="faq-section section-gap with-illustration with-shape grey-bg">
            <div className="container">
                <div className="faq-illustration-img">
                    <img src={illustration} alt="illustration" />
                </div>
                <div className="row justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="faq-content">
                            <div className="section-title mb-40 both-border">
                                <span className="title-tag">FAQs</span>
                            </div>
                            {/* FAQ LOOP */}
                            <Accordion defaultActiveKey="0" className="faq-loop" id="faqAccordion">
                                <Card>
                                    <Card.Header className='row active-header'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="0">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-4 text-white text-left'>How to select the best eCommerce platform for my business?</span>
                                                </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-minus text-white" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="collapseparent">
                                        <Card.Body>
                                        To choose the best eCommerce platform, you need to look at a few things. This includes the ownership cost, hosting proficiency, number and quality of integrations, variety of themes, UX elements, platform speed, its ability to scale, security system, and payment gateway support structure. A few platforms will satisfy all the requirements on these grounds. So the final decision must depend on which platform ensures all the services you need at an optimized cost. So, the key is to look for a platform that provides the best value for money.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="1">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Is a Ready-to-Use solution better than customized solutions?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1" className="collapseparent">
                                        <Card.Body>
                                        Using a low-code or no-code platform does shorten the time to market and makes developing a solution easier. But it also limits your creativity and ability to innovate. However, when coding from scratch to create a bespoke solution, the prerogative to transform your ideas into a developed solution falls on your shoulders, where the limit is your imagination and expertise. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="2">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>What’s the difference between B2C and B2B eCommerce platforms?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2" className="collapseparent">
                                        <Card.Body>
                                        B2C eCommerce platforms address the needs and requirements of an individual customer. As a result, the platforms are built to promote and advertise the products for individual customers only. In B2B eCommerce, the products and solutions are offered to businesses.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Will eCommerce remain relevant in the future?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                        Yes, with time, the online shopping trend is increasing, and eCommerce will become one of the major sources for any type of business to earn and sell their products. Not only will it remain relevant, but businesses that are not adopting the eCommerce model will lose potential customers and business.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Is Amazon an eCommerce platform, and can I build a similar one?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                        Yes, Amazon is an eCommerce platform and one of the biggest ones in the world. At its core, Amazon it a marketplace where sellers and buyers can connect. It is possible to make a marketplace like Amazon, and you can also customize the offerings according to your business needs and model. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {/* End Faq LOOP */}
                        </div>
                    </div>
                </div>
                <div className="circle-img" style={{ backgroundImage: "url(" + circleimg + ")" }} />
            </div>
        </section>
    );
}

export default Faqs;