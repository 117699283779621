import React, { Component, Fragment } from 'react'
import Jobcategory from './Jobcategory'
import Joblist from './Joblist'
import Banner from './Banner'
import ClientSays from '../../layouts/Client-says'
import schema from './schema.json'

class Content extends Component {
    render() {
        return (
            <Fragment>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
                <Banner />
                <Joblist />
                <section className="container-fluid client-section grey-bg">
                    <div className="client-slider section-gap">
                        <ClientSays />
                    </div>
                </section>
            </Fragment>
        );
    }
}

export default Content;