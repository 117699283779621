import React, { useRef } from 'react'
import Slider from 'react-slick'

//import testimonial from '../../../assets/img/testimonial/testimonial.png'
import testimonial from '../../../assets/img/testimonial/feedback.png'
import testimonial_1 from '../../../assets/img/testimonial/5763-hemal-n-soni.jpg'
import testimonial_2 from '../../../assets/img/testimonial/anuppatel.jpg'
import testimonial_3 from '../../../assets/img/testimonial/Nick-Broadhurst.jpg'
import testimonial_4 from '../../../assets/img/testimonial/Jay-Grieves.jpg'
import testimonial_5 from '../../../assets/img/testimonial/Roy-Sencio.jpg'
import testimonial_6 from '../../../assets/img/testimonial/10407-bhriguseth.jpg'
import testimonial_7 from '../../../assets/img/testimonial/5516-ronak-raval.jpg'

const testimonialslide = [
    {
        "img": testimonial_3,
        "name": "Nick Broadhurst",
        "post": "Broadhurst Ambrosini Group",
        "comment": "Very easy to work with and quick. Thanks BSP Technologies."
    },
    {
        "img": testimonial_1,
        "name": "Hemal Soni",
        "post": "Managing Director, Jewelxy",
        "comment": "BSP Technologies is dedicated to working toward the best outcomes. Robust and Reliable All-in-One Solution Provider."
    },
    {
        "img": testimonial_2,
        "name": "Anup Patel",
        "post": "Tyre Channel Limited",
        "comment": "Highly recommend! Very dedicated team and ready to support when required. Excellent Enterprise application and web application builders."
    },
    {
        "img": testimonial_4,
        "name": "Jay Grieves",
        "post": "",
        "comment": "Working with BSP Technologies was wonderful! Fantastic communication skills and great ideas."
    },
    {
        "img": testimonial_5,
        "name": "Roy Sencio",
        "post": "",
        "comment": "Great experience. I will work with them again in the future."
    },
    {
        "img": testimonial_6,
        "name": "Bhrigu Seth",
        "post": "Managing Director, Irie International Pvt. Ltd.",
        "comment": "An amazing boutique IT firm. I go to BSP for all my web and mobile development projects. They have successfully developed and deployed two web & mobile applications for me."
    },
    {
        "img": testimonial_7,
        "name": "Ronak Raval",
        "post": "Founder, LLM Creation",
        "comment": "BSP TECHNOLOGIES did a great job of website designing and also gave us great insight on what they believed will help boost sales."
    }
]



function Testimonials(props) {
    const sliderRef = useRef()

    const next = () => {
        sliderRef.current.slickNext()
    }
    const previous = () => {
        sliderRef.current.slickPrev()
    }

    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 500,
        arrows: false,
        fade: false,
        dots: false,
        swipe: true,
    }
    return (
        <section className="testimonial-section grey-bg">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-start">
                    <div className="col-lg-12 col-md-12">
                        <div className="section-title text-center both-border mb-50">
                            <h2 className="title">Transforming Industries, One Solution at a Time</h2>
                            <span className="title-tag">Innovative Solutions Tailored to Your Sector</span>
                        </div>
                        <Slider className="testimonial-items" ref={sliderRef} id="testimonialSliderOne" {...settings}>
                            {testimonialslide.map((item, i) => (
                                <div key={i} className="testimonial-item  text-center">
                                    <div className="content">
                                        <p><span className="quote-top">
                                            <i className="fal fa-quote-left" />
                                        </span>
                                            {item.comment}
                                            <span className="quote-bottom">
                                                <i className="fal fa-quote-right" />
                                            </span></p>
                                        <div className="author d-flex justify-content-center">
                                            <div className="thumb">
                                                <img src={item.img} alt={item.name} />
                                            </div>
                                            <div className="desc text-left pl-3">
                                                <h4>{item.name}</h4>
                                                <span className={ item.post ? 'd-blok' : 'd-none' }>{item.post}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="testimonial-arrows">
                            <div className="col-12 order-1"><button className="slick-arrow prev-arrow" onClick={previous}><i className="fal fa-long-arrow-left" /></button><button className="slick-arrow next-arrow float-right" onClick={next}><i className="fal fa-long-arrow-right" /></button></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonials img */}

        </section>
    );
}

export default Testimonials;