import React, { Component, Fragment } from 'react'
import Client from '../../layouts/Client'
import LeadingPlatforms from '../../layouts/Client-says'
import ContactForm from '../../layouts/Contact'
import About from './About'
import Banner from './Banner'
import Counter from './Counter'
import CounterDark from './Counter-dark'
import Features from './Features'
import Technoligies from '../../layouts/technologies'
import Service from './Service'
import Testimonials from './Testimonials'
import Video from './Video'
import Whyus from './Whyus'
import Project from './Project'
import Statics from './Statics'
import WhyWeBest from './WhyWeBest'
import TestimonialsNew from './TestimonialsNew'
import Faqs from './Faqs'
import Cta from './Cta'
import Workingprocess from './Workingprocess'
import Industry from './IndustryWeServe'
import schema from "./schema.json";

const Content =(props)=> {
    return (
            <Fragment>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
                <Banner />
                <Client />
                <Service />
                {/* <Project /> */}

                <CounterDark />
                <Technoligies />
                {/* <Industry /> */}
                {/* <Video /> */}
                {/* <Statics />
                <Workingprocess /> */}
                <WhyWeBest />
                <Testimonials />
                <LeadingPlatforms />
                <ContactForm />
                {/* <Faqs /> */}
                {/* <About /> */}
                {/* <Features /> */}
                {/* <Whyus /> */}
            </Fragment>
        );
}

export default Content;