import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

import processbg1 from '../../../assets/img/video-bg/01.jpg'
import processbg2 from '../../../assets/img/video-bg/02.jpg'
import frontend from '../../../assets/img/services/Front-end.webp'
import definingproj from '../../../assets/img/services/UiUxDevelopment/definingproj.svg'
import proresearch from '../../../assets/img/services/UiUxDevelopment/proresearch.svg'
import proanalysis from '../../../assets/img/services/UiUxDevelopment/proanalysis.svg'
import wireprototype from '../../../assets/img/services/UiUxDevelopment/wireprototype.svg'
import designing from '../../../assets/img/services/UiUxDevelopment/designing.svg'
import uxvalidation from '../../../assets/img/services/UiUxDevelopment/uxvalidation.svg'

const processlist = [
    { icon: 'fal fa-coffee', number: '01', title: 'Have A Coffee', text: 'Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis etquasi' },
    { icon: 'fal fa-coffee', number: '02', title: 'Meet With Advisors', text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiumdoloremque' },
    { icon: 'fal fa-coffee', number: '03', title: 'Achieve Your Goals', text: 'Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil mole' },
]

function Workingprocess(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }
    return (
        <section className="working-process-section process-flow-section">
            <div className="container">
                 <div className="section-title text-center both-border mb-30">
                    <span className="title-tag">UI/UX Development Process</span>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={definingproj} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Defining the Product</h3>
                            <p className='mt-3'>
                            Every UI/UX design has a definition, and that is what we understand before touching the pen or pencil. We try to find the product’s context and the reason for its existence. This sets the ball rolling for our design to brainstorm ideas. We conduct stakeholder interviews, create a value proposition for the product, and work on concept sketching. By the end of this phase, we are into a kick-off meeting and set expectations for the design team. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={proresearch} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Product Research</h3>
                            <p className='mt-3'>
                            The second stage in UI/UX development is the research phase. We research the end-users of the product and its market. The time required to complete this phase depends on the product’s complexity and available resources. Moreover, we move into individual and in-depth interviews along with conducting competitive research and analysis. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={proanalysis} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Product Analysis</h3>
                            <p className='mt-3'>
                            All the data we have collected during the research phase is analyzed here, and we create meaningful insights from the data collected. As a result of the analysis, we create user personas, create user stories, and storyboards. The motive of creating these personas is to understand how a user interacts with the product and how they connect with the product.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={wireprototype} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Wireframing and Prototyping</h3>
                            <p className='mt-3'>
                            This is the pre-design stage, where our designers take the traditional pen and paper approach to draw out the product’s elements. If it’s an application, we sketch the interfaces, create a workflow, and work on the wireframe. This is followed by creating low and high-fidelity prototypes along with clickable prototypes.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={designing} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Designing</h3>
                            <p className='mt-3'>
                            Here, we work on the design specifications of all the visual elements involved in the product. This is the stage where we convert the prototypes into a working product, preferably virtually. In addition to this, we work on a design system including components, patterns, and styles. This allows the designers and developers to work together and stage on the same page.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={uxvalidation} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>UX Validation and Testing</h3>
                            <p className='mt-3'>
                            The design phase ends with testing and validating the design we have created. It’s an essential part of the entire design process and allows the design team to ascertain its usability. We hold testing sessions and expose the product to all types of stresses and testing. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="working-circle" /> */}

            {/* <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} /> */}
        </section>
    );
}

export default Workingprocess;