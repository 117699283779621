import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import logo from '../../assets/img/BSP-Technologies-Logo.svg'
import shape1 from '../../assets/img/lines/01.png'
import shape2 from '../../assets/img/lines/02.png'



const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
}

const Footer =(props)=> {
    return (
            <footer>
                <div className="container">
		<div className="footer-widget">
			<div className="row">
				<div className="col-lg-4 col-sm-4 order-1">
					<div className="widget site-info-widget">
						<div className="footer-logo">
							<img src={logo} alt="BSP Technologies"/>
						</div>
						<p>Let's Build Simple, Scalable and profitable solutions to gather</p>
						<ul className="social-links">
							<li><a href='https://www.facebook.com/Bsptechno/' target='_blank'><i className="fab fa-facebook-f"></i></a></li>
							{/* <li><Link to="#"><i className="fab fa-twitter"></i></Link></li>
							<li><Link to="#"><i className="fab fa-instagram"></i></Link></li> */}
							<li><a href='https://www.linkedin.com/company/bsp-technologies/' target='_blank'><i className="fab fa-linkedin-in"></i></a></li>
							{/* <li><Link to="#"><i className="fab fa-dribbble"></i></Link></li> */}
						</ul>
					</div>
				</div>

				<div className="col-lg-8 col-sm-8 order-2">
					<div className="row">
						<div className="col-lg-6 col-sm-4">
							<div className="widget nav-widget">
								<h4 className="widget-title">Services</h4>
								<ul>
									<li><Link to="/web-development">Web Development</Link></li>
									<li><Link to="/mobile-development">Mobile Development</Link></li>	
									<li><Link to="/e-commerce-development">E-Commerce Development</Link></li>	
									<li><Link to="/ui-ux-development">UI/UX Development</Link></li>	
								</ul>
							</div>
						</div>
						<div className="col-lg-6 col-sm-4">
							<div className="widget contact-widget">
								<h4 className="widget-title">Contact Us</h4>
								<p>Talk with Experts Solution Provider</p>
								<ul className="contact-infos">
									<li>
										<a href="tel:+917575001041">
											<i className="fa fa-phone"></i>
											(+91) 7575001041
										</a>
									</li>
									<li>
										<a href="mailto:biz@bsptechno.com">
											<i className="far fa-envelope-open"></i>
											biz@bsptechno.com
										</a>
									</li>
									<li> <i className="fa fa-map-marker-alt"></i> Ahmedabad, Gujrat, India</li>
								</ul>
							</div>
						</div>
					</div>
				</div>					
			</div>
			<div className='row'>
				<div className='col-lg-12 col-sm-12 widget text-center'>
					<div className="d-inline p-2">
						<Link to="/about">About</Link> &nbsp; |  &nbsp;
						<Link to="/blog">Blog</Link> &nbsp; | &nbsp;
						<Link to="/careers">Current Openings</Link> &nbsp; |  &nbsp;
						<Link to="/contact">Let's Discuss</Link>
					</div>
				</div>
			</div>
		</div>
		<div className="footer-copyright">
			<p className="copyright-text">
				<span></span>
				<span>All Right Reserved Design By BSP Technologies</span>
			</p>

            <Link to="#" className="back-to-top" onClick={scrollToTop}><i className="fa fa-angle-up" /></Link>
		</div>
	</div>
                {/* Lines */}
                <img src={shape1} alt="line" className="line-one" />
                <img src={shape2} alt="line" className="line-two" />
            </footer>
        );
    
}

export default Footer;