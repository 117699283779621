import React from 'react'
import { Link } from 'react-router-dom'

import line1 from '../../../assets/img/lines/11.png'
import line2 from '../../../assets/img/lines/12.png'

import icon1 from '../../../assets/img/AboutUs/Clientfirst.svg'
import icon2 from '../../../assets/img/AboutUs/transparent.svg'
import icon3 from '../../../assets/img/AboutUs/teamplayer.svg'
import icon4 from '../../../assets/img/AboutUs/creativity.svg'

const serviceblock = [
    { icon: icon1, title: 'Client-First', text: 'We work for our clients and their success. To fulfill our client’s requirements and help them achieve their goals is the primary value at BSP Technologies. Our designers and developers create solutions transcending our client’s business.' },
    { icon: icon2, title: 'Transparent', text: 'BSP Technologies is an “open book\'. We share our heart and soul with the clients and invite them to become a part of our journey. We share our vulnerabilities, passion, and zeal to achieve success and build strong relationships.' },
    { icon: icon3, title: 'Team Player', text: 'Everyone at BSP Technologies is a team player, and we augment each other’s strengths and work on our weaknesses together. As a company, we aim to achieve the best work-life equation there is and foster growth consistently.' },
    { icon: icon4, title: 'Creativity', text: 'When it comes to being creative, the sky’s the limit for our designers and developers. We formulate new strategies and thought processes to imbibe exclusivity into our work and help our client’s businesses embark on a new journey of success and growth.' },
]


function Service(props) {

    return (
        <section className="service-section grey-bg service-line-shape section-gap">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-50">
                    <span className="title-tag">Our Values Sets us Apart</span>
                    <h2 className="title">Market-Ready and Client-Focused Solutions</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-sm-12 col-10 wow fadeInRight" data-wow-duration="1500ms" data-wow-delay="700ms">
                            <div className="service-box-three border-0">
                                <div className="icon">
                                    <img src={item.icon} alt="" />
                                </div>
                                <h3><Link to="/about">{item.title}</Link></h3>
                                <p>{item.text}</p>
                                {/* <Link to="/servicedetail" className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </Link> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="line-one">
                <img src={line2} alt="line-shape" />
            </div>
            <div className="line-two">
                <img src={line1} alt="line-shape" />
            </div>
        </section>
    );
}
export default Service;