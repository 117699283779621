import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import Header from '../layouts/ComingSoonHeader'
import Content from '../sections/ComingSoon/Content'
import title from '../layouts/Title.json'

const Home = (props)=> {
    window.scrollTo(0, 0);
        return (
            <Fragment>
                <MetaTags>
                {title.length > 0 ? title.map((item, i) => {
                    if(item.page==="Coming Soon")
                    return(
                    
                    <>
                    <title>{item.title}</title>
                    <meta
                        name="description"
                        content={item.description}
                    />
                    </>
                )}) : null}
                </MetaTags>
                <Header />
                <Content />
            </Fragment>
        );
    
}
export default Home;