import React, { Component } from 'react'
import PageHeader from './PageHeader';



class websiteRedesign7ImportantThingsToConsiderContent extends Component {
    render() {
        const { pageH1 } = this.props;
        return (
            <section className="blog-section ">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />
                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">
                                <div className="post-meta">
                                    <p>
                                        There could be many reasons when you choose to redesign your website but one thing is sure that your website plays a crucial role in your business. These days, where online presence is mandatory for every business. If you look at these Statistics where 3 websites are going live every second, you can imagine how important it is to differentiate your business from millions of others and one can’t do without an impressive website.
                                    </p>
                                    <p>
                                        Our guide of things to consider before you plan to redesign your website is a framework to promote success for your business.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>1. How Responsive Is Your Website?</h4>
                                    <p>
                                        In recent years, internet users are shifting from desktop to mobile and tablets. So It does matter that your website looks and feel of any device. Responsive means that your website should be optimized for any device. It should look good for desktop users as well as mobile users. If it’s not then without much delay you should make it responsive because you can’t ignore 40% internet users, whom will be visiting your website from their phone or tablet.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>2. Analyze Your Current Website.</h4>
                                    <p>
                                        Before setting the target, that you want to achieve with the redesign you should analyze your website on what’s working? And what it isn’t? What are features missing? You can certainly take feedbacks from current customers on how to improve things for them?
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>3. Are The Navigations Working?</h4>
                                    <p>
                                        Navigation and scrolling through a page should work smoothly. If your bounce rate is higher than 50% then your navigation is not good enough. When a user visits your website, they should be able to move around. Right? Before making any purchase or decision.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>4. Does Your Website Offer Personalized Content?</h4>
                                    <p>
                                        Personalization is not easy if it is, everyone would be doing it. But, guess what? With personalization, you can differentiate yourself from your competitors. Showing the relevant content to your users is what makes your business unique. You can do personalization based on the demographics and what they are looking. If you’re planning to do so then you have to plan in advance.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>5. What Are Your Objectives?</h4>
                                    <p>
                                        You should clearly define your business/website objective. Is it a blog website? If so your focus will attract visitors and be converting them into new subscribers. Is it an e-commerce website? If so your focus will be to show the relevant products to your customers and increase sales.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>6. Do You Have A Call To Action Button?</h4>
                                    <p>
                                        Call to action tags are the backbone of website conversion. Putting the right call to action at the right time in front of your users can do wonders. Now, based on your objectives you can plan your Call to action and place them accordingly. The users must be told what they should do if they are interested in your business.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>7. List Out All The Features You’d Like To Have?</h4>
                                    <p>
                                        Once your website is responsive, and it navigates properly. It’s time to add some tools/features to make your website more powerful and useful. Again depends on the type of business you have, you can add features like Live chat, Payment integration, social media feeds, Discussion panel, Personalized feed, Sign up form, Analytics, Slideshows, event calendar and so on.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4>Conclusion:</h4>
                                    <p>
                                        Now, everything is in its place. One thing you should remember that when you’re about to launch your new site, you rank and traffics will slow down a little bit especially the organic ones, but you should not worry about it as it is nobody’s fault. The fact is Search engines take time adjusting and listing all your pages when you redesign or launch the website. So it’s not a matter of concern but definitely, you should be aware of it.
                                    </p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default websiteRedesign7ImportantThingsToConsiderContent;