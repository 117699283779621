import http from "../http-common";
class DataService {
  getAll(url) {
    return http.get(url);
  }
  get(id,url) {
    return http.get(url+`/`+id);
  }
  create(data,url) {
    return http.post(url, data);
  }
  update(url,id, data) {
    return http.put(`/${url}/${id}`, data);
  }
  delete(url,id) {
    return http.delete(`/${url}/${id}`);
  }
  
  
}
export default new DataService();