import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import Breadcrumbs from '../../layouts/Breadcrumbs'
import Client from '../../layouts/Client'
import Content from '../../sections/blogdetail/whatAreTheAdvantagesOfWebApplicationContent'
import blogbanner from '../../../assets/img/blog/bsp_banners_01-07-1024x344.jpg'

class whatAreTheAdvantagesOfWebApplication extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <MetaTags>
                    <title>WHAT ARE THE ADVANTAGES OF WEB APPLICATION - BSP Technologies</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header />
                <Breadcrumbs breadcrumb={{ pagename: 'WHAT ARE THE ADVANTAGES OF WEB APPLICATION', breadcrumbimg: blogbanner , sectionURL:'/blog'}} />
                <Content pageH1="WHAT ARE THE ADVANTAGES OF WEB APPLICATION" />
                <Client />
                <Footer />
            </Fragment>
        );
    }
}

export default whatAreTheAdvantagesOfWebApplication;