import React, { Component, Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Breadcrumbs from "../layouts/Breadcrumbs";
import Client from "../layouts/Client";
import Content from "../sections/bloggrid/Content";
import schema from "../sections/bloggrid/blogSchema.json";
import title from "../layouts/Title.json";
import SeoDetails from "../layouts/SeoDetails";

class Bloggrid extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Fragment>
        <SeoDetails page="Blog" />
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
        <Header />
        <Content />
        <section className="client-section">
          <div className="container">
            <div className="client-slider section-gap">
              <Client />
            </div>
          </div>
        </section>
        <Footer />
      </Fragment>
    );
  }
}

export default Bloggrid;
