import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import background from "../../../assets/img/banner/Web-design.jpg";

import whatsAppImg from '../../../assets/img/whatsapp.svg'



const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: false,
    dots: false,
    swipe: true,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: 768,
        settings: {
            arrows: false
        }
    }],
}

function Banner(props) {
    return (
            <section className="banner-section">
                <Slider className="banner-slider" id="bannerSlider" {...settings}>
                    <div>
                        <div className="single-banner pb-5" style={{ backgroundImage: `url(${background})`, height:"100vh"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <div className="banner-content">
                                            <span className="promo-text" data-animation="fadeInDown" data-delay="0.8s">
                                                Code | Build | Deploy | Maintain
                                            </span>
                                            <h1 data-animation="fadeInUp" data-delay="1s">
                                                Rebuilding a new wave of BSP Technologies
                                            </h1>
                                            <h2 data-animation="fadeInUp" data-delay="1s" className='text-white pt-4'>
                                                Unwilling soon!
                                            </h2>
                                            <h4 className="text-white mt-3" data-animation="fadeInUp" data-delay="1s">
                                                Web Development | Mobile App Development | UI/UX Development | Marketplace Development
                                            </h4>
                                            <div className='row justify-content-center'>
                                                <div className='col-auto  pt-3'>
                                                    <a href="mailto:biz@bsptsechno.com" className='text-white'>
                                                        <i className="fas fa-envelope"></i>
                                                        &nbsp;&nbsp;biz@bsptechno.com
                                                    </a>
                                                </div>
                                            </div>
                                            <ul className="btn-wrap">
                                                <li data-animation="fadeInRight" data-delay="1.4s">
                                                    <a href="https://api.whatsapp.com/send?phone=+917575001041&text=Hi, BSP Technologies, I would like to discuss about project." className="main-btn main-btn-2" target='_blank'> <i className="fab fa-whatsapp"></i>&nbsp;&nbsp; Contact Us</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-shapes">
                                <div className="one" />
                                <div className="two" />
                                <div className="three" />
                                <div className="four" />
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        );
    }
export default Banner;