import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import breadcrumbimg from '../../assets/img/banner/About-us-banner-dowhatisgreat.png'

const Breadcrumbs = (props) => {
    return (
        <>
            <section className="breadcrumb-section bg-img-c" style={{ backgroundImage: "url(" + props.breadcrumb.breadcrumbimg + ")" }}>

                <div className="breadcrumb-shapes">
                    <div className="one" />
                    <div className="two" />
                    <div className="three" />
                </div>
            </section>
        </>
    );

}

export default Breadcrumbs;