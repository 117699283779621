import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import img1 from '../../assets/img/clients/01.png'
import img2 from '../../assets/img/clients/02.png'
import img3 from '../../assets/img/clients/Acero-03.png'
import img4 from '../../assets/img/clients/04.png'
import img5 from '../../assets/img/clients/PocketDeal-05.png'
import img6 from '../../assets/img/clients/Limo-Party-Bus-EU-06.png'
// import img7 from '../../assets/img/clients/07.png'
import img8 from '../../assets/img/clients/Aurobindo-Realty-08.png'
import img9 from '../../assets/img/clients/Arboricluture-09.png'
// import img10 from '../../assets/img/clients/10.png'
import img11 from '../../assets/img/clients/Airsheen-Services-11.png'
// import img12 from '../../assets/img/clients/12.png'
import img13 from '../../assets/img/clients/V-Stock-Transfer-13.png'
import img14 from '../../assets/img/clients/Jewelxy-Marketplace-14.png'

const clientslide = [
	{ img: img1, alt: "Thumbtack Bugle" },
	{ img: img2, alt: "Shell Helix" },
	{ img: img3, alt: "Acero" },
	{ img: img4, alt: "Radiance" },
	{ img: img5, alt: "PocketDeal" },
	{ img: img6, alt: "Limo Party Bus EU" },
	// { img: img7 , alt:"" },
	{ img: img8, alt: "Aurobindo Realty" },
	{ img: img9, alt: "Arboricluture" },
	// { img: img10 , alt:"" },
	{ img: img11, alt: "Airsheen Services" },
	// { img: img12 , alt:"" },
	{ img: img13, alt: "V Stock Transfer" },
	{ img: img14, alt: "Jewelxy Marketplace" },
]

const settings = {
	infinite: true,
	slidesToShow: 5,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	speed: 500,
	arrows: false,
	fade: false,
	dots: false,
	swipe: true,

	responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 576,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 1,
			},
		},
	],
}
const Client = (props) => {
	return (
		<section className="client-section">
			<div className="container">
				<div className="client-slider section-gap">
					<Slider className="row align-items-center justify-content-between" id="clientSlider" {...settings}>
						{clientslide.map((item, i) => (
							<div key={i} className="col text-center">
								<img src={item.img} alt="" />
							</div>
						))}
					</Slider>
				</div>
			</div>
		</section >
	);

}

export default Client;