import React, { Component, Fragment } from 'react';
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import Breadcrumbs from '../layouts/Breadcrumbs'
import Client from '../layouts/Client'
import Content from '../sections/contact/Content'
import SeoDetails from '../layouts/SeoDetails'

const Contact = props => {
    window.scrollTo(0, 0);
    return (
        <Fragment>
            <SeoDetails page="Contact" />
            <Header />
            <Content />
            <section className="client-section">
                <div className="container">
                    <div className="client-slider section-gap line-top">
                        <Client />
                    </div>
                </div>
            </section>
            <Footer />
        </Fragment>
    );

}

export default Contact;