import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

import processbg1 from '../../../assets/img/video-bg/01.jpg'
import processbg2 from '../../../assets/img/video-bg/02.jpg'
import frontend from '../../../assets/img/services/Front-end.webp'
import businessmodel from '../../../assets/img/services/eCommerceDevelopment/businessmodel.svg'
import requirements from '../../../assets/img/services/eCommerceDevelopment/requirements.svg'
import choosesolution from '../../../assets/img/services/eCommerceDevelopment/choosesolution.svg'
import develpmnt from '../../../assets/img/services/eCommerceDevelopment/develpmnt.svg'
import designplatform from '../../../assets/img/services/eCommerceDevelopment/designplatform.svg'
import launch from '../../../assets/img/services/eCommerceDevelopment/launch.svg'
import support from '../../../assets/img/services/eCommerceDevelopment/support.svg'
import intfeatures from '../../../assets/img/services/eCommerceDevelopment/intfeatures.svg'

const processlist = [
    { icon: 'fal fa-coffee', number: '01', title: 'Have A Coffee', text: 'Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis etquasi' },
    { icon: 'fal fa-coffee', number: '02', title: 'Meet With Advisors', text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiumdoloremque' },
    { icon: 'fal fa-coffee', number: '03', title: 'Achieve Your Goals', text: 'Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil mole' },
]

function Workingprocess(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }
    return (
        <section className="working-process-section process-flow-section">
            <div className="container">
                 <div className="section-title text-center both-border mb-30">
                    <span className="title-tag">BSP Techno eCommerce Development Process</span>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={businessmodel} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Finalize the eCommerce Business Model</h3>
                            <p className='mt-3'>
                            You need to finalize which product(s) you are going to sell and by which business model. eCommerce has grown by leaps and bounds in mostly two models, Business to Business (B2B) and Business to Consumer (B2C). B2C is selling the product to end-users and individual customers. B2B is selling your products to businesses and other organizations.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={requirements} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Gather the Requirements</h3>
                            <p className='mt-3'>
                            Based on your choice of product(s) and business model, we gather more requirements to understand your objectives and motive for running the business. It includes knowing your audience, your organization’s USP, competitors, market conditions, and a few other things. Knowing these will help us carve a unique identity for your business and build a business plan.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={choosesolution} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Choose the eCommerce Development Solution</h3>
                            <p className='mt-3'>
                            As the eCommerce business model is growing and in high demand, many platforms are available. These platforms provide ready-to-use functions and solutions with edit access to create the required solutions. We can help you in choosing the right development platform for your business. For this, we take note of the potential platform’s responsiveness to the design and usage. We check its product management capabilities, in-built CMS, shopping cart proficiency, and the checkout page plus payment processing system. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={intfeatures} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Integrate the Required Features</h3>
                            <p className='mt-3'>
                            We then create a list of features relevant to your eCommerce business. Our developers will edit the features taken from existing templates or decide on new ones specifically for your platform. Along with this, we help you create and implement a content strategy to promote your products and optimize the listings.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={designplatform} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Design the Platform</h3>
                            <p className='mt-3'>
                            Simultaneous with the development part, our designers take the lead and work on the platform’s design. We follow the best design practices coupled with an innovative thought process to create the right design for your solution. Designing is important as it helps attract audiences and makes them want to engage with your website more. We integrate functionality within the design to ensure that your eCommerce platform is easy to use and pleasing to look at. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={develpmnt} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Development and Codifying</h3>
                            <p className='mt-3'>
                            The next step in the process is writing the code for the eCommerce solution. Our developers are proficient in several eCommerce platforms and solutions. We follow the best coding standards and practices and ensure that the codebase is stable, reliable, and scalable. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={launch} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Deployment and Launch</h3>
                            <p className='mt-3'>
                            Before deploying the eCommerce store, we run several tests to ensure that your store performs as intended in every respect. The launch process includes uploading all the files and content on the server, ensuring the store has the required certificates, etc. We have deployment experts taking care of the CI/CD pipelines to ensure a seamless deployment. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={support} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Maintenance and Support</h3>
                            <p className='mt-3'>
                            At BSP Techno, we are always committed to our clients and have become an integral part of their success. We provide the required maintenance and support to our clients as and when they require. You can contact us to fix an issue or upgrade the eCommerce store, add new functionality, etc. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="working-circle" /> */}

            {/* <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} /> */}
        </section>
    );
}

export default Workingprocess;