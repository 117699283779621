import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import img from '../../assets/img/tab-block.jpg'
import frontend from '../../assets/img/services/Front-end.webp'
import backend from '../../assets/img/services/Back-end.webp'
import Databases from '../../assets/img/services/Databases.png'
import CloudServices from '../../assets/img/services/Cloud-Services.webp'
import JS from '../../assets/img/technologies/frontend/JavaScript.svg'
import Adnroid from '../../assets/img/technologies/frontend/Adnroid.svg'
import AngularJS from '../../assets/img/technologies/frontend/AngularJS.svg'
import flutter from '../../assets/img/technologies/frontend/flutter.svg'
import iOS from '../../assets/img/technologies/frontend/iOS.svg'
import ReactICON from '../../assets/img/technologies/frontend/React.svg'
import ReactNative from '../../assets/img/technologies/frontend/ReactNative.svg'
import TypeScript from '../../assets/img/technologies/frontend/TypeScript.svg'
import Wordpress from '../../assets/img/technologies/frontend/Wordpress.svg'
import laravel from '../../assets/img/technologies/backend/laravel.svg'
import DotNet from '../../assets/img/technologies/backend/microsoft-dot-net-icon.webp'
import nodejs from '../../assets/img/technologies/backend/nodejs.svg'
import php from '../../assets/img/technologies/backend/php.svg'
import Firebase from '../../assets/img/technologies/database/Firebase.svg'
import Firestore from '../../assets/img/technologies/database/Firestore.svg'
import MySQL from '../../assets/img/technologies/database/MySQL.svg'
import MsSQL from '../../assets/img/technologies/database/Mssql-server-icon.webp'
import PostageSQL from '../../assets/img/technologies/database/PostageSQL.svg'
import Redis from '../../assets/img/technologies/database/Redis.svg'
import AWSICON from '../../assets/img/technologies/cloud/AWS.svg'
import cloudways from '../../assets/img/technologies/cloud/cloudways.svg'
import digitalOcean from '../../assets/img/technologies/cloud/digital-ocean.svg'
import GoogleCloud from '../../assets/img/technologies/cloud/Google-cloud.svg'
import heroku from '../../assets/img/technologies/cloud/heroku.svg'



const Frontend = [
    { icon: flutter, title: 'Flutter' },
    { icon: ReactICON, title: 'React' },
    { icon: Wordpress, title: 'Wordpress' },
    { icon: iOS, title: 'iOS' },
    { icon: Adnroid, title: 'Adnroid' },
    { icon: JS, title: 'JS' },
    { icon: AngularJS, title: 'AngularJS' },
    { icon: ReactNative, title: 'React Native' },
    { icon: TypeScript, title: 'Type Script' },
    
]

const Backend = [
    { icon: php, title: 'PHP' },
    { icon: nodejs, title: 'Node Js' },
    { icon: laravel, title: 'Laravel' },
    { icon: DotNet, title: '.Net' },
]
const Database = [
    { icon: MySQL, title: 'MySQL' },
    { icon: PostageSQL, title: 'Postage SQL' },
    { icon: MsSQL, title: 'MsSQL' },

    { icon: Firebase, title: 'Firebase' },
    { icon: Firestore, title: 'Firestore' },
    { icon: Redis, title: 'Redis' },
]
const CloudServicesData = [
    { icon: AWSICON, title: 'AWS' },
    { icon: cloudways, title: 'Cloudways' },
    { icon: digitalOcean, title: 'Digital Ocean' },
    { icon: GoogleCloud, title: 'Google Cloud' },
    { icon: heroku, title: 'Heroku' },
]

function Advancedtab(props) {
    return (
        <section className="advanced-tab section-gap">
            <div className="container">
                <div className="section-title text-center both-border mb-30">
                <h2 className="title">Tech Marvels for Powerful Solutions</h2>
                    <span className="title-tag">Mastering the Art of Tech Tools</span>
                    
                </div>
                {/* Tabs Buttons */}
                <div className="tab-buttons d-none d-lg-block d-md-block">
                    <Tab.Container defaultActiveKey="frontend">
                        <div className="row justify-content-center">
                            <div className='col-md-4'>
                                <Nav variant="tabs" className='d-block text-center'>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="frontend" className='service-page-advance-tab'>Front-end Development</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="backend" className='service-page-advance-tab'>Backend Development</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="database" className='service-page-advance-tab'>Database Management</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="cloud-services" className='service-page-advance-tab'>Cloud Services</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-md-8'>
                                <Tab.Content className='service-tab-content'>
                                    <Tab.Pane eventKey="frontend">
                                        <div className="tab-text-block">
                                            {/* <div className="row mb-30">
                                                <div className="col-12">
                                                    <div className="block-image">
                                                        <img src={frontend} alt="" width={80} className='img img-fluid' />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <h2 className="title">Frontend</h2>
                                                </div>
                                            </div> */}
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>At BSP Technologies, we excel in front-end development, creating captivating user interfaces using a wide array of tools and technologies. With a focus on seamless user experiences, we harness the power of popular frameworks like Flutter, React, Wordpress, iOS, and more.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Frontend.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span className='bg-white'>
                                                                <img className='img img-fluid' alt={item.title} src={item.icon} width={80} />
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="backend">
                                        <div className="tab-text-block">
                                            {/* <div className="row mb-30">
                                                <div className="col-12">
                                                    <div className="block-image">
                                                        <img src={backend} alt="" width={80} className='img img-fluid' />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <h2 className="title">Backend</h2>
                                                </div>
                                            </div> */}
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>Our skilled team specializes in backend development, building robust server-side applications using a diverse set of technologies like PHP, Node.js,Laravel and .NET. We ensure scalable and high-performance solutions tailored to meet your business needs. </p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Backend.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span className='bg-white'>
                                                                <img className='img img-fluid' alt={item.title} src={item.icon} width={80} />
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="database">
                                        <div className="tab-text-block">
                                            {/* <div className="row mb-30">
                                                <div className="col-12">
                                                    <div className="block-image">
                                                        <img src={Databases} alt="" width={80} className='img img-fluid' />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <h2 className="title">Database</h2>
                                                </div>
                                            </div> */}
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>BSP Technologies is proficient in database management, handling data with precision and efficiency. Our expertise spans a range of databases, including MySQL, PostgreSQL, MsSQL, Firebase, and Redis. We optimize data storage and retrieval for seamless user interactions.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Database.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span className='bg-white'>
                                                                <img className='img img-fluid' alt={item.title} src={item.icon} width={80} />
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="cloud-services">
                                        <div className="tab-text-block">
                                            {/* <div className="row mb-30">
                                                <div className="col-12">
                                                    <div className="block-image">
                                                        <img src={CloudServices} alt="" width={80} className='img img-fluid' />
                                                    </div>
                                                </div>
                                            </div> */}
                                            {/* <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <h2 className="title">Cloud Services</h2>
                                                </div>
                                            </div> */}
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p> Embrace the power of the cloud with our cloud services expertise. We work with leading cloud platforms such as AWS, Google Cloud, Digital Ocean, Cloudways, and Heroku to ensure optimal performance, scalability, and cost-effectiveness for your applications.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {CloudServicesData.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span className='bg-white'>
                                                                <img className='img img-fluid' alt={item.title} src={item.icon} width={80} />
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                
                <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Ready to harness the power of cutting-edge technologies? Connect with our skilled team to leverage our expertise in a wide range of programming languages, frameworks, databases, and cloud services.</span>                    
                </div>
                <div className="view-more-btn text-center mt-30">
                    <Link to="/contact" className="main-btn main-btn-3">Unlock Tech Expertise</Link>
                </div>
            </div>
        </section>
    );
}

export default Advancedtab;