import React from 'react'
import { Link } from 'react-router-dom'

//import illustration from '../../../assets/img/illustration/01.png'
import illustration from '../../../assets/img/illustration/about-us.jpg'


function About(props) {
    return (
        <section className="about-section about-illustration-img section-gap">
            <div className="container">
                <div className="illustration-img">
                    <img src={illustration} alt="" />
                </div>
                <div className="row no-gutters justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="about-text">
                            <div className="section-title left-border mb-40">
                                <span className="title-tag">About Us</span>
                                <h2 className="title">Get things done with right approch</h2>
                            </div>
                            <p className="mb-25">
							Technocrats at BSP, Believe in Providing Best Solutions by Understanding, Exploring and Optimising.
						</p>
						<ul className="about-list">
							<li> <i className="far fa-check"></i> 10 Years of Establishment</li>
							<li> <i className="far fa-check"></i> 100K of Successfully Completed Hours of Work</li>
							<li> <i className="far fa-check"></i> 300+ Satisfied Clients</li>
							<li> <i className="far fa-check"></i> 98% Client retention rate</li>
						</ul>
                            <Link to="/about" className="main-btn">Learn More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;