import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

import processbg1 from '../../../assets/img/video-bg/01.jpg'
import processbg2 from '../../../assets/img/video-bg/02.jpg'
import Requirement from '../../../assets/img/services/webDevelopment/reqanalysis.svg'
import Roadmap from '../../../assets/img/services/webDevelopment/roadmap.svg'
import Design from '../../../assets/img/services/webDevelopment/design.svg'
import Structure from '../../../assets/img/services/webDevelopment/structure.svg'
import coding from '../../../assets/img/services/webDevelopment/coding.svg'
import testing from '../../../assets/img/services/webDevelopment/testing.svg'
import launch from '../../../assets/img/services/webDevelopment/launch.svg'
import support from '../../../assets/img/services/webDevelopment/support.svg'
import consistent from '../../../assets/img/services/webDevelopment/consistent.svg'

const processlist = [
    { icon: 'fal fa-coffee', number: '01', title: 'Have A Coffee', text: 'Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis etquasi' },
    { icon: 'fal fa-coffee', number: '02', title: 'Meet With Advisors', text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiumdoloremque' },
    { icon: 'fal fa-coffee', number: '03', title: 'Achieve Your Goals', text: 'Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil mole' },
]

function Workingprocess(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }
    return (
        <section className="working-process-section process-flow-section">
            <div className="container">
                 <div className="section-title text-center both-border mb-30">
                    <span className="title-tag">The Process Behind Building Web Solutions</span>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={Requirement} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Requirement Analysis</h3>
                            <p className='mt-3'>
                            For a web solution, the work begins by gathering the initial requirements. This helps us identify the key aspects of developing the solution and know your objectives and goals. We understand why you want to build the solution, what you want to offer to your customers, and what’s unique about your web solution. This helps us move ahead to build a well-oiled plan, including all the necessary elements.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={Roadmap} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Building the Roadmap</h3>
                            <p className='mt-3'>
                            With the initial information understood, we create the blueprint and strategic plan. The analysts, designers, and developers sit together to create the sitemap or structure according to the requirement. This structure acts as a lightning beacon for the entire development team. It also acts as a visual representation of progress for the team. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={Design} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Working on the Design</h3>
                            <p className='mt-3'>
                            This is where the web solutions take shape as we decide and add the visual content. While working on the design, we keep the target audience in mind. We work on the wireframes, mockups, and prototypes. Here, we can present some tangible results regarding the solution. We handpick the right color palette, images, graphics, and other visual elements to make the design stunning and functional. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={coding} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Coding and Programming</h3>
                            <p className='mt-3'>
                            This is the stage where all our designs and ideas will begin to take their final form. We decide which technologies can be used for the development process. Whether we will employ a CMS or a framework or use a programming language, etc. The graphic elements we decided on will be codified, and the features, functions, back-end, and front-end systems will be added. All the sub-pages and their structure will be codified.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={testing} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Testing the Web Solution</h3>
                            <p className='mt-3'>
                            Testing is the next most important part of the web development process. We test every single aspect of the solution, including its performance, security, functions, etc. As we check these elements, correction is done to broken ones. We run the code script to check for any typo errors, find bugs, and use code validators to check whether the code satisfies existing coding standards.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={launch} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Launch and Deployment</h3>
                            <p className='mt-3'>
                            As everything is checked and our quality assurance team is satisfied with the result, we initiate the launch sequence. Using a File Transfer Protocol (FTP), we upload the solution onto a server. Here too, we run a test to check whether all the files are uploaded on the server correctly. Within the launch sequence, we also work on setting DNS records followed by setting up a live testing site. During deployment, we use CI/CD systems to introduce automation and continuous monitoring of the website.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={support} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Support and Maintenance</h3>
                            <p className='mt-3'>
                            Once your website is live and running, our maintenance team will be available to help you with any sort of support and maintenance tasks. As part of our regular maintenance activities, we can help you discover new features and integrate better solutions according to your business requirements. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={consistent} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Consistent Development</h3>
                            <p className='mt-3'>Consistent development simplifies the creative and technical process as we work in containers to update, upgrade, and improve the solution. Our work includes consistently deploying environments across different pipelines, machines, and over the cloud. We use different development tools and patterns to provide your end-users with a consistent experience.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="working-circle" /> */}

            {/* <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} /> */}
        </section>
    );
}

export default Workingprocess;