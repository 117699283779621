import React, { Component, Fragment } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import SeoDetails from '../layouts/SeoDetails';

class privacyPolicy extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <SeoDetails page="privacyPolicy" />
                <Header />
                <div class="container">
                    <header class="mt-5">
                        <div class="display-4">Privacy Policy</div>
                    </header>
                    <main>
                        <section class="mt-4">
                            <div class="font-weight-bold">Introduction</div>
                            <p>BSP Technologies is committed to protecting the privacy of our clients and website visitors. This privacy policy outlines how we collect, use, and protect your personal information.</p>
                        </section>
                        <section class="mt-4">
                            <div class="font-weight-bold">Information Collection</div>
                            <p>We collect personal information such as names, email addresses, and contact numbers when they are voluntarily provided by you, for example, when you fill out a contact form or sign up for our newsletter.</p>
                        </section>
                        <section class="mt-4">
                            <div class="font-weight-bold">Use of Information</div>
                            <p>Your personal information is used to respond to your inquiries, provide services, and communicate with you about our offerings, provided you have given your consent.</p>
                        </section>
                        <section class="mt-4">
                            <div class="font-weight-bold">Cookies</div>
                            <p>Our website uses cookies to enhance user experience and analyze website traffic. You have the option to disable cookies through your browser settings.</p>
                        </section>
                        <section class="mt-4">
                            <div class="font-weight-bold">Security</div>
                            <p>We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure.</p>
                        </section>
                        <section class="mt-4">
                            <div class="font-weight-bold">Changes to This Policy</div>
                            <p>We may update our privacy policy from time to time. Any changes will be posted on this page.</p>
                        </section>
                        <section class="mt-4 mb-5">
                            <div class="font-weight-bold">Contact Us</div>
                            <p>If you have any questions regarding this privacy policy, please contact us at [your contact information].</p>
                        </section>
                    </main>
                </div>
                <Footer />
            </Fragment>
        );
    }
}

export default privacyPolicy;
