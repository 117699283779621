import React, { useState } from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import img1 from "../../../assets/img/counter/Project-Delivered.svg";
import img4 from "../../../assets/img/counter/Client-Satisfaction.svg";
import img5 from "../../../assets/img/counter/Industries.svg";
import img6 from "../../../assets/img/counter/Referral-Clients.svg";
import img7 from "../../../assets/img/counter/Countries.svg";

const counterblock = [
    { icon: img5, count: '18', plusIcon: '+', title: 'Industries' },
    { icon: img1, count: '400', plusIcon: '+', title: 'Projects Delivered' },
    { icon: img4, count: '300', plusIcon: '+', title: 'Happy Clients' },
    { icon: img6, count: '98', plusIcon: '%', title: 'Referral Clients' },
    { icon: img7, count: '8', plusIcon: '', title: 'Countries' },
]

function Counter(props) {
    const [focus, setFocus] = useState(false)

    return (

        <section className="fact-section-two">
            <div className="container-fluid">
                <div className="fact-two-inner">
                    <div className="container">
                        <div className="fact-boxes row justify-content-between align-items-center">
                            {counterblock.map((item, i) => (
                                <div key={i} className="col-sm-auto">
                                    <div className="fact-box fact-box-two text-center mb-40">
                                        <div className="icon">
                                            {/* <i className={item.icon} /> */}
                                            <img src={item.icon} style={{ width: "60px" }} alt={item.title}/>
                                            {/*  <img1 /> */}
                                        </div>
                                        <CountUp start={focus ? 0 : null} end={parseInt(item.count)} duration={5} redraw={true}>
                                            {({ countUpRef }) => (
                                                <div>
                                                    <span className="counter" ref={countUpRef} />
                                                    <VisibilitySensor
                                                        onChange={isVisible => {
                                                            if (isVisible) {
                                                                setFocus(true);
                                                            }
                                                        }}
                                                    >
                                                        <span className="plus-icon">{item.plusIcon}</span>
                                                    </VisibilitySensor>
                                                </div>
                                            )}
                                        </CountUp>
                                        <p className="title">{item.title}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Counter;