import React, { Component,Fragment } from 'react'
import { Link } from 'react-router-dom'
import Banner from './Banner'

import img1 from '../../../assets/img/blog/bsp_banners_01-07-1024x344.jpg'
import img2 from '../../../assets/img/blog/bsp_banners_01-06-1024x344.jpg'
import img3 from '../../../assets/img/blog/bsp_banners_01-05-1024x344.jpg'
import img4 from '../../../assets/img/blog/bsp_banners_01-03-1024x345.jpg'
import img5 from '../../../assets/img/blog/bsp_banners_01-02-1024x344.jpg'
import img6 from '../../../assets/img/blog/bsp_banners_01-01-1024x267.jpg'

import img24 from '../../../assets/img/blog/The-ultimate-guide-to-WooCommerce-On-WordPress-4.jpg'
import img26 from '../../../assets/img/blog/most-exciting-mobile-application-2.jpg'

import img31 from '../../../assets/img/blog/8-Reasons-why-companies-should-not-opt-for-a-cheap-website-1-300x139.jpg'
import img32 from '../../../assets/img/blog/A-Perfect-Guide-for-Creating-Successful-App-2.jpg'
import img33 from '../../../assets/img/blog/10-Programming-languages-demand-in-2018-2.jpg'
import img34 from '../../../assets/img/blog/5-Ways-to-Improve-the-Speed-of-WordPress-Website-2.jpg'
import img35 from '../../../assets/img/blog/6-Important-Tools-Every-Small-Medium-Business-Must-Know-2-1.jpg'
import img36 from '../../../assets/img/blog/A-quick-guide-to-2.jpg'

import img51 from '../../../assets/img/blog/Website-Redesign-7-important-thing-to-consider-1.jpg'
import img52 from '../../../assets/img/blog/6-Reasons-to-choose-1-1.jpg'


const blogblock = [
    { img: img1, title: 'Brighten Up Someone’s May With Max Huber Turne',subtitle:'Marketing' ,text: 'What is a Web Application? A web application is a PC program that enables you to sign in to a web address so as to submit and recover information to/f...', postdate: 'December 23, 2019', urltext: 'Learn More', url: '/blog/what-are-the-advantages-of-web-application' },
    { img: img2, title: 'What is laravel? And why do we Choose laravel?',subtitle:'Marketing' , text: 'Laravel PHP framework was developed with the aim to make a few development tasks easier for developers such as authentication, sessions, caching, and ...', postdate: 'December 16, 2019', urltext: 'Learn More', url: '/blog/what-is-laravel-and-why-do-we-choose-laravel' },
    { img: img3, title: 'SAAS (Software as service)',subtitle:'Marketing' , text: 'What is SAAS(Software as service)? Software as a service (or SaaS) is a way of delivering applications over the Internet—as a service. SaaS app...', postdate: 'December 14, 2019', urltext: 'Learn More', url: '/blog/saas' },
    { img: img4, title: 'Difference between Website and  Web Application',subtitle:'Marketing' , text: 'What is a Web Application? A web application is a software or program which is accessible using any web browser. Its frontend is usually created using...', postdate: 'December 11, 2019', urltext: 'Learn More', url: '/blog/difference-between-website-and-web-application' },
    { img: img5, title: 'Advantages to choose Swift UI.',subtitle:'Marketing' , text: 'It has an interactive environment (the playground) While the language itself has a lot of concepts and moving parts you actually can get use...', postdate: 'December 9, 2019', urltext: 'Learn More', url: '/blog/advantages-to-choose-swift-ui' },
    { img: img6, title: 'Prime Reasons to Choose Flutter For iOS Development & Android Development In 2019',subtitle:'Marketing' , text: 'Xamarin, PhoneGap, React Native — and now Flutter. Flutter is another expansion in the list picked for versatile application advancement structures....', postdate: 'December 6, 2019', urltext: 'Learn More', url: '/blog/prime-reasons-to-choose-flutter-for-ios-development-android-development-in-2019' },

    { img: img24, title: 'The ultimate guide to WooCommerce On WordPress',subtitle:'Web Development' , text: 'Building an e-commerce store is a hell of a trip. Now, with platforms like WooCommerce, you can have the entire store built with just a few clicks. Be...', postdate: 'July 17, 2018', urltext: 'Learn More', url: '/blog/the-ultimate-guide-to-woocommerce' },
    { img: img26, title: 'Most exciting mobile application development trends in 2018',subtitle:'Android, iOS, Mobile Application Development' , text: 'In 2018, when it comes to developing mobile apps, or we’ll see new trends or an excellent update of what users are already using. Before address...', postdate: 'June 29, 2018', urltext: 'Learn More', url: '/blog/most-exciting-mobile-application-development-trends' },

    { img: img31, title: '8 Reasons why companies should not opt for a cheap website',subtitle:'Web Development' ,text: 'A unique website is what companies expect today. Organizations make a common mistake of opting for cheap websites, resulting in the loss of customers....', postdate: 'June 28, 2018', urltext: 'Learn More', url: '/blog/8-reasons-why-companies-should-not-opt-for-a-cheap-website' },
    { img: img32, title: 'A Perfect Guide for Creating Successful App',subtitle:'Mobile Application Development' , text: 'In today’s world, everyone thinks for making a unique mobile application. The idea itself does not guarantee that the application will become a ...', postdate: 'June 22, 2018', urltext: 'Learn More', url: '/blog/a-perfect-guide-for-creating-successful-app' },
    { img: img33, title: '10 Programming languages demand in 2018',subtitle:'Web Development' , text: 'Today we are going to talk about 10 lucrative, popular programming occupations. In case you’re keen on getting into the coding industry for a lu...', postdate: 'June 11, 2018', urltext: 'Learn More', url: '/blog/10-programming-languages-demand-in-2018' },
    { img: img34, title: '5 Ways to Improve the Speed of WordPress Website',subtitle:'Web Development' , text: 'Do you have problems with your WordPress website that does not load or load too slowly? These are the types of problems that you do not want to have w...', postdate: 'June 9, 2018', urltext: 'Learn More', url: '/blog/5-ways-to-improve-the-speed-of-wordpress-website' },
    { img: img35, title: '6 Important Tools Every Small & Medium Business Must Know',subtitle:'Marketing' , text: 'Are you still in those days when Google Docs used to be an ideal choice for small businesses? Today, you will find a multitude of tools for dealing wi...', postdate: 'June 8, 2018', urltext: 'Learn More', url: '/blog/6-important-tools-every-small-medium-business-must-know' },
    { img: img36, title: 'A Quick Guide to Use of Hashtag in Social Media',subtitle:'Marketing' , text: 'Did you know that hashtag words generate a double engagement, which includes clicks, retweets, favourites and responses? Hashtags have become so popul...', postdate: 'June 1, 2018', urltext: 'Learn More', url: '/blog/a-quick-guide-to-use-of-hashtag-in-social-media' },


    { img: img51, title: 'Website redesign: 7 important things to consider',subtitle:'Website Design' ,text: 'There could be many reasons when you choose to redesign your website but one thing is sure that your website plays a crucial role in your business. Th...', postdate: 'May 15, 2018', urltext: 'Learn More', url: '/blog/website-redesign-7-important-things-to-consider' },
    { img: img52, title: 'Reasons to Choose Laravel for enterprise application',subtitle:'Android, Web Development' , text: 'The Laravel framework is one of the most exciting piece of work to come along in PHP community. Creator and developer Taylor Otwell has done an amazin...', postdate: 'May 15, 2018', urltext: 'Learn More', url: '/blog/reasons-to-choose-laravel-for-enterprise-application' }

]

class Content extends Component {
    render() {
    return (
        <Fragment>
            <Banner />
            <section className="blog-section section-gap-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            {/* Blog loop(Grid) */}
                            <div className="blog-loop grid-blog row justify-content-center">
                                {/* Single Post */}
                                {blogblock.map((item, i) => (
                                    <div key={i} className="col-lg-4 col-md-6 col-12 col-tiny-12">
                                        <div className="single-post-box">
                                            <div className="post-thumb">
                                                <img src={item.img} alt="" />
                                            </div>
                                            <div className="post-content">
                                                <span className="post-date"><i className="far fa-calendar-alt" />{item.postdate}</span>
                                                <h3 className="title">
                                                    <Link to={item.url}>{item.title}</Link>
                                                </h3>
                                                <p className='post-link'><strong>{item.subtitle}</strong></p>
                                                <p>{item.text}</p>
                                                <Link to={item.url} className="post-link">
                                                    {item.urltext} <i className="far fa-long-arrow-right" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/* Pagination */}
                            {/* <Pagination /> */}
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
        );
    }
}

export default Content;