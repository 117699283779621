import React, { Component } from 'react'
import PageHeader from './PageHeader';


class SAASContent extends Component {
    render() {
        const { pageH1 } = this.props;
        return (
            <section className="blog-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />
                        
                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">

                                <div className="post-meta">
                                    <p>
                                        Software as a service (or SaaS) is a way of delivering applications over the Internet—as a service. SaaS applications are sometimes called Web-based software, on-demand software, or hosted software. Instead of installing and maintaining software, you simply access it via the Internet, freeing yourself from complex software and hardware management.
                                        Whatever the name, SaaS applications run on a SaaS provider’s servers. The provider manages access to the application, including security, availability, and performance.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4 className="with-check"><i className="far fa-check-circle" />SAAS Characteristics</h4>
                                    <p>
                                        A good way to understand the SaaS model is by thinking of a bank, which protects the privacy of each customer while providing service that is reliable and secure—on a massive scale. A bank’s customers all use the same financial systems and technology without worrying about anyone accessing their personal information without authorisation.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4 className="with-check"><i className="far fa-check-circle" />Multitenant Architecture</h4>
                                    <p>
                                        A multitenant architecture, in which all users and applications share a single, common infrastructure and code base that is centrally maintained. Because SaaS vendor clients are all on the same infrastructure and code base, vendors can innovate more quickly and save the valuable development time previously spent on maintaining numerous versions of outdated code.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4 className="with-check"><i className="far fa-check-circle" />SAAS Trends</h4>
                                    <p>
                                        Organisations are now developing SaaS integration platforms (or SIPs) for building additional SaaS applications. The consulting firm Saugatuck Technology calls this the “third wave” in software adoption: when SaaS moves beyond standalone software functionality to become a platform for mission-critical applications.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4 className="with-check"><i className="far fa-check-circle" />SAAS Harnesses the Consumer Web</h4>
                                    <p>
                                        With the SaaS model, you can customise with point-and-click ease, making the weeks or months it takes to update traditional business software seem hopelessly old fashioned.
                                    </p>
                                </div>
                                <div className="post-meta">
                                    <h4 className="with-check"><i className="far fa-check-circle" />Easy customisation</h4>
                                    <p>
                                        The ability for each user to easily customise applications to fit their business processes without affecting the common infrastructure. Because of the way SaaS is architected, these customisations are unique to each company or user and are always preserved through upgrades. That means SaaS providers can make upgrades more often, with less customer risk and much lower adoption cost.
                                    </p>
                                    <p>Better Access</p>
                                    <p>Improved access to data from any networked device while making it easier to manage privileges, monitor data use, and ensure everyone sees the same information at the same time.</p>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SAASContent;