import React, { useRef } from 'react'
import Slider from 'react-slick'

//import testimonial from '../../../assets/img/testimonial/testimonial.png'
import testimonial from '../../../assets/img/testimonial/feedback.png'
import testimonial_1 from '../../../assets/img/testimonial/5763-hemal-n-soni.jpg'
import testimonial_2 from '../../../assets/img/testimonial/anuppatel.jpg'
import testimonial_3 from '../../../assets/img/testimonial/Nick-Broadhurst.jpg'
import testimonial_4 from '../../../assets/img/testimonial/Jay-Grieves.jpg'
import testimonial_5 from '../../../assets/img/testimonial/Roy-Sencio.jpg'

import testimonial_6 from '../../../assets/img/testimonial/10407-bhriguseth.jpg'
import testimonial_7 from '../../../assets/img/testimonial/5516-ronak-raval.jpg'

const testimonialslide = [
	{ img: testimonial_3, name: 'Nick Broadhurst', post: 'Broadhurst Ambrosini Group', comment: 'Very easy to work with and quick. Thanks Nirav.' },
    { img: testimonial_1, name: 'Hemal Soni', post: 'Managing Director, Jewelxy', comment: 'Though it’s a large project, BSP Technologies is dedicated to working toward the best outcomes. Robust and Reliable All-in-One Solution Provider' },
    { img: testimonial_2, name: 'Anup Patel', post: 'Tyre Channel Limited', comment: 'Very dedicated team and ready to support when required, excellent Enterprise application and web application builders in this team. We definitely work with them again. I recommend to higher this team for your long-term project, you won\'t regrate...' },
    { img: testimonial_4, name: 'Jay Grieves', post: '', comment: 'Working with Nirav was wonderful! His communications skills are fantastic and he has great ideas. I hope I get to work with him again!' },
    { img: testimonial_5, name: 'Roy Sencio', post: '', comment: 'Great experience. I will work with him again in the future.' },
    { img: testimonial_6, name: 'Bhrigu Seth', post: 'Managing Director, Irie International Pvt. Ltd.', comment: 'An amazing boutique IT firm - I have been associated with them for over three years now. I go to BSP for all my web and mobile development projects. They have successfully developed and deployed two web & mobile applications for me.' },
    { img: testimonial_7, name: 'Ronak Raval', post: 'Founder, LLM Creation', comment: 'BSP TECHNOLOGIES did a great job of website designing and also gave us great insight on what they believed will help boost sales.' },
]

function Testimonials(props) {
    const sliderRef = useRef()

    const next = () => {
        sliderRef.current.slickNext()
    }
    const previous = () => {
        sliderRef.current.slickPrev()
    }

    const settings = {
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		autoplay: false,
		autoplaySpeed: 5000,
		speed: 500,
		arrows: false,
		fade: false,
		dots: false,
		swipe: true,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 400,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	}
    return (
        <section className="testimonial-section grey-bg">
            <div className="container">
                <div className="row justify-content-center justify-content-lg-start">
                    <div className="col-12">
                        <div className="section-title both-border text-center">
                            <span className="title-tag">We Meet Clients Idea</span>
                            <h2 className="title">An Exclusive Feedbacks</h2>
                        </div>
						<Slider className="row align-items-center justify-content-between testimonial-items" ref={sliderRef} id="clientSlider" {...settings}>
							{testimonialslide.map((item, i) => (
								<div key={i} className="col">
									<div className="service-box bg-white p-2 text-center servicePageTestimonial">
										<div className="author">
											<div className="thumb">
												<img className='rounded-circle shadow-1-strong mb-3' src={item.img} alt="img" width={100} height={100} />
											</div>
											<div className="desc">
												<h4>{item.name}</h4>
												<span>{item.post}</span>
											</div>
										</div>
										<div className="content">
											<p>
												<span className="quote-top mr-2">
													<i className="fas fa-quote-left" style={{color:'#f17a2180'}} />
												</span>
													{item.comment}
												<span className="quote-bottom ml-2">
													<i className="fas fa-quote-right" style={{color:'#f17a2180'}} />
												</span>
											</p>
										</div>
									</div>
								</div>
							))}
						</Slider>
                        <div className="testimonial-arrows row">
                            <div className="col-12 order-1"><button className="slick-arrow next-arrow" onClick={next}><i className="fal fa-long-arrow-right" /></button></div>
                            <div className="col-12 order-2"><button className="slick-arrow prev-arrow" onClick={previous}><i className="fal fa-long-arrow-left" /></button></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Testimonials img */}
            {/* <div className="testimonial-img">
                <img src={testimonial} alt="" />
            </div> */}
        </section>
    );
}

export default Testimonials;