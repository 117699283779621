import React from 'react'
import { Link } from 'react-router-dom'

import illustration from '../../../assets/img/AboutUs/about.jpeg'

function About(props) {

    return (
        <section className="about-section about-illustration-img section-gap">
            <div className="container">
                <div className="illustration-img">
                    <img src={illustration} alt="" />
                </div>
                <div className="row no-gutters justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                    <div className="about-text">
						<div className="section-title both-border mb-40">
							<span className="title-tag">About Us</span>
						</div>
						<p className="mb-25">
						BSP Technologies work with a disruptive mindset and innovative thinking to create exceptional experiences for the end-users. We want to bring the most amazing digital experiences to your customers and stakeholders by realizing the power of technology.
						</p>
						<p className="mb-25">
						We build applications, web apps, websites, and digital solutions with a passion and a relentless drive to create extraordinary experiences. We have world-class infrastructure and an exceptional team of experts fusing their minds, approaches, and strategies in the most efficient way possible. Our solutions are driven by the client’s requirements and a motive to attain business goals.
						</p>
						{/* <ul className="about-list">
							<li> <i className="far fa-check"></i> 10 Years of Establishment</li>
							<li> <i className="far fa-check"></i> 100K of Successfully Completed Hours of Work</li>
							<li> <i className="far fa-check"></i> 300+ Satisfied Clients</li>
							<li> <i className="far fa-check"></i> 98% Client retention rate</li>
						</ul>
						<a href="#" className="main-btn">Let's Discuss</a> */}
					</div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;