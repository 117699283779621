import React, { Component, Fragment } from "react";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import Breadcrumbs from "../../layouts/Breadcrumbs";
import Client from "../../layouts/Client";
import Content from "../../sections/WebDevelopment/Content";
import breadcrumbimg from "../../../assets/img/banner/About-us-banner-dowhatisgreat.png";
import SeoDetails from "../../layouts/SeoDetails";

class Service extends Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <Fragment>
        <SeoDetails page="WebDevelopment" />
        <Header />
        {/* <Breadcrumbs breadcrumb={{ pagename: 'Services', breadcrumbimg: breadcrumbimg }} /> */}
        <Content />
        <Footer />
      </Fragment>
    );
  }
}

export default Service;
