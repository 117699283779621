import React ,{useEffect,useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// Preloader
import Preloader from './components/layouts/Preloader'
// Pages
import Home from './components/pages/Home'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Bloggrid from './components/pages/Bloggrid'
import whatAreTheAdvantagesOfWebApplication from './components/pages/blog-details/whatAreTheAdvantagesOfWebApplication'
import whatIsLaravelAndWhyDoWeChooseLaravel from './components/pages/blog-details/whatIsLaravelAndWhyDoWeChooseLaravel'
import SAAS from './components/pages/blog-details/SAAS'
import differenceBetweenWebsiteAndWebApplication from './components/pages/blog-details/differenceBetweenWebsiteAndWebApplication'
import advantagesToChooseSwiftUi from './components/pages/blog-details/advantagesToChooseSwiftUi'
import primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019 from './components/pages/blog-details/primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019'
import theUltimateGuideToWoocommerce from './components/pages/blog-details/theUltimateGuideToWoocommerce'
import mostExcitingMobileApplicationDevelopmentTrends from './components/pages/blog-details/mostExcitingMobileApplicationDevelopmentTrends'
import reasonsWhyCompaniesShouldNotOptForaCheapWebsite from './components/pages/blog-details/reasonsWhyCompaniesShouldNotOptForaCheapWebsite'
import aPerfectGuideForCreatingSuccessfulApp from './components/pages/blog-details/aPerfectGuideForCreatingSuccessfulApp'
import programmingLanguagesDemandIn2018 from './components/pages/blog-details/programmingLanguagesDemandIn2018'
import waysToImproveTheSpeedOfWordpressWebsite from './components/pages/blog-details/waysToImproveTheSpeedOfWordpressWebsite'
import importantToolsEverySmallMediumBusinessMustKnow from './components/pages/blog-details/importantToolsEverySmallMediumBusinessMustKnow'
import aQuickGuideToUseOfHashtagInSocialMedia from './components/pages/blog-details/aQuickGuideToUseOfHashtagInSocialMedia'
import websiteRedesign7ImportantThingsToConsider from './components/pages/blog-details/websiteRedesign7ImportantThingsToConsider'
import reasonsToChooseLaravelForEnterpriseApplication from './components/pages/blog-details/reasonsToChooseLaravelForEnterpriseApplication'
import webDevelopment from './components/pages/Service/WebDevelopment'
import mobileDevelopment from './components/pages/Service/MobileDevelopment'
import eCommerceDevelopment from './components/pages/Service/eCommerceDevelopment'
import UiUxDevelopment from './components/pages/Service/UiUxDevelopment'
import webPortal from './components/pages/Solutions/Service'
import ComingSoon from './components/pages/CommingSoon'
import Careers from './components/pages/Careers'
import privacyPolicy from './components/pages/privacyPolicy'
import Apply from './components/pages/apply'
import PageNotFound from './components/pages/PageNotFound'

import { v4 as uuidv4 } from 'uuid';
import TagManager from 'react-gtm-module'
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

// Production
const tagManagerArgs = {
  gtmId: 'GTM-MRFQ39T'
}

//stage
/* const tagManagerArgs = {
  gtmId: 'GTM-T5QQG2M'
} */

function App(){
  const [pathname] = useState({ pagename: window.location.pathname });
  const [pagename] = useState({ pathname: window.location.pagename });
  const handleAcceptCookie = () => {
    TagManager.initialize(tagManagerArgs);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      user_id : uuidv4(),
      page: {
        url: pathname,
        title: pagename
      }
    });
  };

  useEffect(() => {
    handleAcceptCookie();
    getCookieConsentValue();
  });
  return (
    <Router basename={"/"}>
      <CookieConsent
        location="bottom"
        buttonClasses='main-btn main-btn-3 d-none'
        declineButtonText = 'OK'
        declineButtonClasses = 'btn btn-link text-white mr-2'
        disableButtonStyles="false"
        enableDeclineButton
        style={{ background: "#002543",textAlign:"left",alignItems: "center"}}
      >
        <p style={{ fontSize: "13px" }}>We employ cookies to enhance your browsing experience. By continuing to use this site, you agree to our cookie policy.</p>
      </CookieConsent>
      <GoogleReCaptchaProvider
    reCaptchaKey="6Lf2ME4jAAAAAOUILuUZwmGbnF71_DM2Jk1pCA2i"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
    container={{ // optional to render inside custom element
      parameters: {
        badge: '[inline|bottomright|bottomleft]', // optional, default undefined
      }
    }}
  >
  </GoogleReCaptchaProvider>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
       
        {/* Blog Detail Routes Start*/}
        <Route path="/blog/what-are-the-advantages-of-web-application" component={whatAreTheAdvantagesOfWebApplication} />
        <Route path="/blog/what-is-laravel-and-why-do-we-choose-laravel" component={whatIsLaravelAndWhyDoWeChooseLaravel} />
        <Route path="/blog/saas" component={SAAS} />
        <Route path="/blog/difference-between-website-and-web-application" component={differenceBetweenWebsiteAndWebApplication} />
        <Route path="/blog/advantages-to-choose-swift-ui" component={advantagesToChooseSwiftUi} />
        <Route path="/blog/prime-reasons-to-choose-flutter-for-ios-development-android-development-in-2019" component={primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019} />
        <Route path="/blog/the-ultimate-guide-to-woocommerce" component={theUltimateGuideToWoocommerce} />
        <Route path="/blog/most-exciting-mobile-application-development-trends" component={mostExcitingMobileApplicationDevelopmentTrends} />
        <Route path="/blog/8-reasons-why-companies-should-not-opt-for-a-cheap-website" component={reasonsWhyCompaniesShouldNotOptForaCheapWebsite} />
        <Route path="/blog/a-perfect-guide-for-creating-successful-app" component={aPerfectGuideForCreatingSuccessfulApp} />
        <Route path="/blog/10-programming-languages-demand-in-2018" component={programmingLanguagesDemandIn2018} />
        <Route path="/blog/5-ways-to-improve-the-speed-of-wordpress-website" component={waysToImproveTheSpeedOfWordpressWebsite} />
        <Route path="/blog/6-important-tools-every-small-medium-business-must-know" component={importantToolsEverySmallMediumBusinessMustKnow} />
        <Route path="/blog/a-quick-guide-to-use-of-hashtag-in-social-media" component={aQuickGuideToUseOfHashtagInSocialMedia} />
        <Route path="/blog/website-redesign-7-important-things-to-consider" component={websiteRedesign7ImportantThingsToConsider} />
        <Route path="/blog/reasons-to-choose-laravel-for-enterprise-application" component={reasonsToChooseLaravelForEnterpriseApplication} />
        <Redirect path="/blog/*" to="/404" status="404"/>
        <Route path="/blog" component={Bloggrid} /> 
        {/* Blog Detail Routes Start*/}
       
        <Route path="/web-development" component={webDevelopment} />
        <Route path="/mobile-development" component={mobileDevelopment} />
        <Route path="/e-commerce-development" component={eCommerceDevelopment} />
        <Route path="/ui-ux-development" component={UiUxDevelopment} />
        <Route path="/web-portal" component={webPortal} />
        <Route path="/careers" component={Careers} />
        <Route path="/privacy-policy" component={privacyPolicy} />
        <Route path="/ComingSoon" component={ComingSoon} />
        {/* <Route path="/todo-app" component={TODOApp} /> */}
        <Route path="/job-application/:name" component={Apply} />
        <Route path="/404" component={PageNotFound} />
        <Redirect path="*" to="/404" status="404"/>
      </Switch>
    </Router>
  );
}

export default App;