import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import Breadcrumbs from '../../layouts/Breadcrumbs'
import Client from '../../layouts/Client'
import Content from '../../sections/blogdetail/importantToolsEverySmallMediumBusinessMustKnowContent'
import blogbanner from '../../../assets/img/blog/6-Important-Tools-Every-Small-Medium-Business-Must-Know-2-1.jpg'

class importantToolsEverySmallMediumBusinessMustKnow extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <MetaTags>
                    <title>6 IMPORTANT TOOLS EVERY SMALL & MEDIUM BUSINESS MUST KNOW - BSP Technologies</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header />
                <Breadcrumbs breadcrumb={{ pagename: '6 IMPORTANT TOOLS EVERY SMALL & MEDIUM BUSINESS MUST KNOW' , breadcrumbimg: blogbanner }} />
                <Content pageH1="6 IMPORTANT TOOLS EVERY SMALL & MEDIUM BUSINESS MUST KNOW"/>
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap">
                            <Client />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default importantToolsEverySmallMediumBusinessMustKnow;