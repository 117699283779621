import React, {  useEffect, useState, Fragment, useCallback  } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import illustration from '../../../assets/img/contactUs/Contactus.jpg'
import { store} from "../../../actions/dashboard";
import api from '../../../common/api';
import Banner from './Banner'
import ClientSays from '../../layouts/Client-says'
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';
const Content =(props)=> {
    var pageUrl = window.location.href;
    var pageTitle = "Home Page";
    var page_request_details = {
        'pageTitle' : pageTitle,
        'pageUrl' : pageUrl
    }
    const SITE_KEY = "6Lf2ME4jAAAAAOUILuUZwmGbnF71_DM2Jk1pCA2i";
    const [frmData, setFrmData] = useState({
        name: "",
        mobile: "",
        email:"",
        message:"",
        page_request_details:JSON.stringify(page_request_details),
        inquiry_from:pageTitle,
        resume:"",
        reCaptcha_token:"",
    });
    const [errors,setError] = useState({});
    const onChangeHandler =(event) =>{
        const {name,value}  =event.target;
        setFrmData((preValue)=>{
            return {
                ...preValue,
                [name]:value,
            };
        });
        setError((preValue)=>{
            return {
                ...preValue,
                [name]:"",
            };
        });
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
              setFrmData((preValue)=>{
                  return {
                      ...preValue,
                      'reCaptcha_token':token,
                  };
              });
            });
        });
    }
    const handleValidSubmit = (e) => {
        e.preventDefault();
        let errors = {};
        let formIsValid = true;
        if(!frmData.name){
            errors["name"] = "Please enter your name.";
        }
       
        if(!frmData.email){
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(frmData.email)) {
            formIsValid = false;
            errors["email"] = 'Invalid email address.'
        }
        if(!frmData.mobile){
            formIsValid = false;
            errors["mobile"] = "Please enter your Mobile no.";
        }
        if(!frmData.message){
            formIsValid = false;
            errors["message"] = "Please enter your Message.";
        }
        setError(errors);
        if(formIsValid==true){
           props.store(frmData,api.CONTACT);
        }
    }
    return (
        <Fragment>
            <Banner />
            <section className="contact-section contact-page section-gap-top">
                <div className="container">
                    <div className="contact-info section-gap-bottom">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="illustration-img text-center">
                                    <img src={illustration} alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                            <div className="contact-info-content">
                            <div className="section-title both-border mb-40">
                                <span className="title-tag">Contact Us</span>
                                <h2 className="title">Call for Consultations, Guidance, and Support</h2>
                            </div>
                            <p>We provide a 15-minute No-Cost, No-Obligation call. Use this opportunity to talk with experts.</p>

                            <ul>
                                <li className="phone">
                                    <a href="tel:+917575001041"><i className="fa fa-phone"></i>(+91) 7575001041</a>
                                </li>
                                <li><i className="far fa-envelope-open"></i><a href="mailto:biz@bsptechno.com">biz@bsptechno.com</a></li>
                                <li><i className="fa fa-map-marker-alt"></i>Ahmedabad, Gujrat, India</li>
                            </ul>
                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="fact-section-two contact-section contact-page">
                <div className='container'>
                    <div className="fact-two-inner contact-form grey-bg">
                        <div className="row no-gutters justify-content-center">
                            <div className="col-10">
                                <div className="section-title text-center mb-40">
                                    <h2 className="title text-white">Share your Requirements Here</h2>
                                </div>
                                <form onSubmit={handleValidSubmit}>
                                <div className="row">
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Full Name" name="name" value={frmData.name} onChange={onChangeHandler}/>
                                                <span className="icon"><i className="far fa-user-circle" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["name"]}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="email" placeholder="Your Email Address" name="email" value={frmData.email} onChange={onChangeHandler} />
                                                <span className="icon"><i className="far fa-envelope-open" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["email"]}</span>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Phone" name="mobile" value={frmData.mobile} onChange={onChangeHandler} />
                                                <span className="icon"><i className="fa fa-phone" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["mobile"]}</span>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-group textarea">
                                                <textarea placeholder="Write Message" name="message" value={frmData.message} onChange={onChangeHandler} />
                                                <span className="icon"><i className="far fa-pencil" /></span>
                                            </div>
                                            <span style={{color: "#000"}}>{errors["message"]}</span>
                                        </div>
                                        <div className="col-12 text-center">
                                            <button type="submit" data-event="send-contact" className="main-btn main-btn-4">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="container-fluid client-section grey-bg">
                <div className="client-slider section-gap">
                    <ClientSays />
                </div>
            </section>
            <section className="contact-section contact-page">
                <div className="container-fluid container-1600">
                    <div className="contact-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14682.392705599328!2d72.5256887!3d23.0751887!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5d83a7c8768aab43!2sBSP%20Technologies!5e0!3m2!1sen!2sin!4v1649093856733!5m2!1sen!2sin"style={{ border: 0 }} allowFullScreen aria-hidden="false" tabIndex={0} />
                    </div>
                </div>
            </section>
        </Fragment>
        );
}
const mapStateToProps = (state) =>
{
    const { url, singledata, error_msg, updateData, listData } = state.dashboards;
    return { url, singledata, error_msg, updateData, listData };
};

export default connect(mapStateToProps, { store })(Content);

