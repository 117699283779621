import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import backend_development from '../../../assets/img/services/webDevelopment/backend.svg'
import custom_development from '../../../assets/img/services/webDevelopment/customweb.svg'
import saas_Development from '../../../assets/img/services/webDevelopment/SaaS.svg'
import progressive_Development from '../../../assets/img/services/webDevelopment/pwa.svg'
import frontend_Development from '../../../assets/img/services/webDevelopment/frontend.svg'
import digital_Development from '../../../assets/img/services/webDevelopment/digtransform.svg'

const serviceblock = [
    { icon: frontend_Development, title: 'Front-end Web Development', text: 'How your web solution looks is pivotal to attracting, retaining, and converting customers. Our expert team of front-end developers will design and build magnetic interfaces for your website, web app, and solutions.' },
    { icon: backend_development, title: 'Back-end Web Development', text: 'A super-talented team of back-end developers works together to set up the most agile, proactive, and robust back-end structure to support the front-end functions and deliver a seamless experience to the end users.' },
    { icon: saas_Development, title: 'SaaS Application Development', text: 'Digitize your business process and interactions with customers via intuitive SaaS products. We create smooth interfaces and integrate the relevant APIs to help businesses present seamless functionality with different access models.' },
    { icon: progressive_Development, title: 'Progressive Web Apps', text: 'The mobile-first web applications built with delectable UI and UX elements is a great recipe for your business’ success. We create PWAs that streamline your road to success and give incredible performance to help you stand out from the competition.' },
    { icon: digital_Development, title: 'Digital Transformation', text: 'Create the most effective digital transformation journey for your business to leverage the wide extent of benefits provided by online transactions and conversations between your business and customers. Our solutions will get the best ROI and enhance your business visibility.' },
    { icon: custom_development, title: 'Custom Web Application Development Services', text: 'Unleash the true power of a web application with our customized development services. We strategize, ideate, create, code, design, develop, and test your web application to give unparalleled performance and seamless compatibility.' },
    /* { icon: backend_development, title: 'Best Web Development Company India', text: 'As the best web development company in India, we delve deeper into the dynamics of web apps delivering value-added solutions. We adhere to utmost professionalism and excellence in object-oriented technologies. Our designed applications help you to build meaningful relationships with your customers.' }, */
]

function Service(props) {
    return (
        <section className="service-section shape-style-one section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Building Web Solutions and Services</span>
				<h2 className="title">Create Hype, Make Noise, and Discover New Opportunities with BSP Techno Web Development Services.</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center">
                                <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                {/* <a href='javascript:void(0)' className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                        <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
            <div className="dots-line">
                <img src={lines} alt="" />
            </div>
        </section>
    );
}

export default Service;