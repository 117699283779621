import React, { Component } from 'react'
import PageHeader from './PageHeader';


class whatIsLaravelAndWhyDoWeChooseLaravelContent extends Component {
    render() {

        const { pageH1 } = this.props;
        return (
            <section className="blog-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />
                        
                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">
                        
                                <div className="post-content">
                                    <ul>
                                        <li>Laravel PHP framework was developed with the aim to make a few development tasks easier for developers such as authentication, sessions, caching, and routing.</li>
                                        <li>That means the web application development challenges are resolved using the Laravel PHP framework.</li>
                                        <li>Now, let’s check out what are the benefits that you get if your web application is developed using the Laravel framework.</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />1. In-built Authentication & Authorization System</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Control access to secure resources</li>
                                        <li><i className="fa fa-square mr-2" />Handle requested access claims with ease</li>
                                        <li><i className="fa fa-square mr-2" />Deny unauthorized requests</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />2. Better Handling of Security & Technical Vulnerabilities</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Better and more secure performance promise</li>
                                        <li><i className="fa fa-square mr-2" />Elimination of critical vulnerability issues and security concerns</li>
                                        <li><i className="fa fa-square mr-2" />Faster debugging and fixing of security and technical errors</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />3. Simplified Mail Integration System</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Notify users on performing each and every activity</li>
                                        <li><i className="fa fa-square mr-2" />Seamlessly integrate mail notification systems</li>
                                        <li><i className="fa fa-square mr-2" />Send emails and notifications via SMS & Slack</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />4. Cached Memory Integration for Better Performance</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Better memory management, automatically</li>
                                        <li><i className="fa fa-square mr-2" />High-speed execution</li>
                                        <li><i className="fa fa-square mr-2" />Improved backend performance</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />5. Easier Exception Handling</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Improved information delivery to end-users through real-time notifications in case of an exception</li>
                                        <li><i className="fa fa-square mr-2" />Increased client satisfaction ratio due to user-friendly and informative interface</li>
                                        <li><i className="fa fa-square mr-2" />Enhanced usability for the end-user without friction or hiccups</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />6. Seamless Automation Testing</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Faster testing due to automation</li>
                                        <li><i className="fa fa-square mr-2" />Automation ensures no gaps are left in testing of core features</li>
                                        <li><i className="fa fa-square mr-2" />Accurate performance ratio calculation by handling multiple scenarios</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />7. Seamless Automation Testing</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Faster development and handling of feature addition requests</li>
                                        <li><i className="fa fa-square mr-2" />No conflict between UI/UX designers and developers</li>
                                        <li><i className="fa fa-square mr-2" />Easy debugging at any stage of development</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />8. Simplified URL Routing Configuration</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Fewer chances of blank URL redirection</li>
                                        <li><i className="fa fa-square mr-2" />Better user experience and interactivity</li>
                                        <li><i className="fa fa-square mr-2" />Quicker development owing to automatic loading of URL routing configuration</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />9. Efficient Task Scheduling and Management Configuration</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Easy task and process management through automated development, maintenance and communication of schedules</li>
                                        <li><i className="fa fa-square mr-2" />Automated handling of redundant tasks like sending periodic emails to subscribers or automatic cleanup of data</li>
                                        <li><i className="fa fa-square mr-2" />Simplified time-management for schedules using a single CRON entry</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />10. Highly Secure Framework</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Quickly develop and scale application without worrying of security</li>
                                        <li><i className="fa fa-square mr-2" />Handle complex security vulnerabilities without putting in extra efforts</li>
                                        <li><i className="fa fa-square mr-2" />In-built fundamental security features for enhanced security</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />11. Seamless Database Migration</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Faster database syncing between development machinery</li>
                                        <li><i className="fa fa-square mr-2" />Easier and quick database migration without hassles</li>
                                        <li><i className="fa fa-square mr-2" />In-built database migration mechanism</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />12. Seamless Database Migration</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />No need to invest time and effort in building object-oriented structures</li>
                                        <li><i className="fa fa-square mr-2" />In-built library for infusing added functionality in an app</li>
                                        <li><i className="fa fa-square mr-2" />Comprehensive object-oriented programming</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default whatIsLaravelAndWhyDoWeChooseLaravelContent;