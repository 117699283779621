import React, { Component, Fragment } from 'react'
import Banner from './Banner'
import whatsAppImg from '../../../assets/img/whatsapp.svg'

const Content =(props)=> {
    return (
            <Fragment>
                <Banner />                
            </Fragment>
        );
}

export default Content;