import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import backend_development from '../../../assets/img/icons/Backend-Development.png'
import Front_end_web_development from '../../../assets/img/icons/Front-end-Development.png'
import Mobile_App_Development from '../../../assets/img/icons/mobile-development.png'

const serviceblock = [
    { icon: backend_development, title: 'Mission', text: 'Our mission is simple; we want your solution to be the best in the industry. To achieve this, we hustle, learn, implement, and test rigorously to make your customer’s life as easy as possible with innovative products.' },
    { icon: Front_end_web_development, title: 'Vision', text: 'We believe Digital is the way of the future. We wish to touch upon every human with great digital solutions and products. We aim to develop, design, and optimize digital experiences constantly.' }
]

function MissionVision(props) {
    return (
        <section className="shape-style-one section-gap">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Our Mission and Vision</span>
				<h2 className="title">Transformative Solutions Transcending Mindset</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center grey-bg" style={{color: "#f5f5f5 !important"}}>
                                {/* <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div> */}
                                <h3><Link to="/about">{item.title}</Link></h3>
                                <p>{item.text}</p>
                                <Link to="/about" className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default MissionVision;