import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import illustration from '../../../assets/img/services/webDevelopment/faq.jpg'
import circleimg from '../../../assets/img/services/webDevelopment/faq2.JPG'
import $ from 'jquery';

function Faqs(props) {

    return (
        <section className="faq-section section-gap with-illustration with-shape grey-bg">
            <div className="container">
                <div className="faq-illustration-img">
                    <img src={illustration} alt="illustration" />
                </div>
                <div className="row justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="faq-content">
                            <div className="section-title mb-40 both-border">
                                <span className="title-tag">FAQs</span>
                            </div>
                            {/* FAQ LOOP */}
                            <Accordion defaultActiveKey="0" className="faq-loop" id="faqAccordion">
                                <Card>
                                    <Card.Header className='row active-header'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="0">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-4 text-white text-left'>What types of web applications can you build?</span>
                                                </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-minus text-white" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="collapseparent">
                                        <Card.Body>
                                        We have the team and expertise to design and build several types of web applications. You can hire us for a static or dynamic web app, an eCommerce store, a single-page application, a portal web application, and CMS, among others. We can build each of these applications with stunning visuals and performance-ready structure. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="1">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Do web apps work on mobile as well?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1" className="collapseparent">
                                        <Card.Body>
                                        Web applications are optimized to run on a web browser and a mobile device providing the users the same experience as they would get with a native mobile application. However, mobile web applications are not native applications. The former is written in HTML and CSS. They need a browser to run. Moreover, they do have the same feel as a native application. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="2">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Do you provide SEO-friendly web solutions?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2" className="collapseparent">
                                        <Card.Body>
                                        Yes, we have in-house SEO experts who work with the design and development team to help create an SEO-friendly website, mobile or web application. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>What’s the difference between a web app, website, and SaaS solution?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                        A web application runs on a web browser, but it's not the same as a website. Websites have a different structure than web applications. Moreover, a website is basically sharing static content with the end users, but a web application is made to interact with the end users. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>What sort of support and maintenance models do you provide?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4" className="collapseparent">
                                        <Card.Body>
                                        We deliver different types of support and maintenance models, which are customizable according to your requirements and needs. From corrective maintenance to condition-based maintenance, we can create a bespoke support model for your business.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="5">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Are there different engagement models available with BSP Techno?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5" className="collapseparent">
                                        <Card.Body>
                                        Yes, our engagement models cater to the requirements of our clients. We can deliver the work with Fixed-Price model, the Time and Material Model, and Offshore Development Model.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="6">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>How do you communicate with your clients and manage the project?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="6" className="collapseparent">
                                        <Card.Body>
                                        For communication purposes, we have a dedicated project manager who will converse directly with the clients. We use advanced solutions and software to organize the project and ensure timely delivery. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="7">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>What is the ideal team composition you choose for a development project?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="7" className="collapseparent">
                                        <Card.Body>
                                        Ideally, we include one business analyst, one project manager, two developers, two designers, and one quality assurance professional in a team. The team composition can change depending on the project’s scale and size. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {/* End Faq LOOP */}
                        </div>
                    </div>
                </div>
                <div className="circle-img" style={{ backgroundImage: "url(" + circleimg + ")" }} />
            </div>
        </section>
    );
}

export default Faqs;