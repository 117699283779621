import React, { Component } from 'react';
import PageHeader from './PageHeader'; // Import PageHeader


class whatAreTheAdvantagesOfWebApplicationContent extends Component {
    render() {
        const { pageH1 } = this.props;

        return (
            <section className="blog-section pt-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />

                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">

                                <div className="post-content">
                                    <h4 className="with-check"><i className="far fa-check-circle" /> WHAT IS A WEB APPLICATION?</h4>
                                    <p>
                                        A web application is a PC program that enables you to sign in to a web address so as to submit and recover information to/from a database over the web, these projects are created utilizing web advancements, for example, HTML, CSS, JS and so on and can be gotten to utilizing your favoured internet browser.
                                    </p>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" /> MOST RELEVANT KINDS OF WEB APPS:</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Static web application</li>
                                        <li><i className="fa fa-square mr-2" />Dynamic web application.</li>
                                        <li><i className="fa fa-square mr-2" />Online store or e-commerce.</li>
                                        <li><i className="fa fa-square mr-2" />Portal web app.</li>
                                        <li><i className="fa fa-square mr-2" />Animated web application.</li>
                                        <li><i className="fa fa-square mr-2" />Web application with a ‘content management system.</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" /> WHAT CAN YOU DO IN A WEB APPLICATION?</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Analyze Campaigns of data collected from disturbed offices across country or world</li>
                                        <li><i className="fa fa-square mr-2" />Display reports in Graphical Form</li>
                                        <li><i className="fa fa-square mr-2" />Order Goods with Online Catalogue, easy searching allows customer to keep track of orders and budgets</li>
                                        <li><i className="fa fa-square mr-2" />Produce Estimates – Attract visitors to your site. You gain details of what they are interested. You are available 24/7</li>
                                        <li><i className="fa fa-square mr-2" />Educate your workforce e.g. lifestyle</li>
                                        <li><i className="fa fa-square mr-2" />Lifestyle questionnaire for employees, advises on a range of wellbeing issues including diet, exercise, & drinking.</li>
                                        <li><i className="fa fa-square mr-2" />Deliver News</li>
                                        <li><i className="fa fa-square mr-2" />Provide product information online</li>
                                        <li><i className="fa fa-square mr-2" />Provide task information for workers on-site via mobile devices</li>
                                        <li><i className="fa fa-square mr-2" />Allow remote works to enter job completion and get sign off on-site</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" /> ADVANTAGES OF WEB APPLICATIONS:</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Centralized data is secure and easy to backup.</li>
                                        <li><i className="fa fa-square mr-2" />Reduce business costs- less time spent talking to customers over the phone; eliminate printed materials; allow users to update their own details.</li>
                                        <li><i className="fa fa-square mr-2" />Zero install – all PCs have a browser</li>
                                        <li><i className="fa fa-square mr-2" />Quick and easy updates.</li>
                                        <li><i className="fa fa-square mr-2" />Reach anybody, anywhere in the world.</li>
                                        <li><i className="fa fa-square mr-2" />Available24 X 7</li>
                                        <li><i className="fa fa-square mr-2" />Low spec PCs or smartphones can be used.</li>
                                        <li><i className="fa fa-square mr-2" />Online training can be completed at user’s own time and pace.</li>
                                        <li><i className="fa fa-square mr-2" />Direct access to latest information – for Employees where ever they are located.</li>
                                        <li><i className="fa fa-square mr-2" />Always up-to-date.</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" /> DISADVANTAGES OF WEB APPLICATIONS:</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Slower, as run over the internet</li>
                                        <li><i className="fa fa-square mr-2" />Internet not always 100% available</li>
                                        <li><i className="fa fa-square mr-2" />Interfaces often not as sophisticated</li>
                                        <li><i className="fa fa-square mr-2" />Can take longer to develop as they are more complex</li>
                                        <li><i className="fa fa-square mr-2" />Have to support different browsers and different versions</li>
                                        <li><i className="fa fa-square mr-2" />Security risks</li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default whatAreTheAdvantagesOfWebApplicationContent;