import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import illustration from '../../../assets/img/illustration/What-We-Do.png'
import circleimg from '../../../assets/img/faq-circle.png'
import $ from 'jquery';

function Faqs(props) {

    return (
        <section className="faq-section section-gap with-illustration with-shape grey-bg">
            <div className="container">
                <div className="faq-illustration-img">
                    <img src={illustration} alt="illustration" />
                </div>
                <div className="row justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="faq-content">
                            <div className="section-title mb-40 left-border">
                                <span className="title-tag">FAQ</span>
                                <h2 className="title">Frequently Asked Questions</h2>
                            </div>
                            {/* FAQ LOOP */}
                            <Accordion defaultActiveKey="0" className="faq-loop" id="faqAccordion">
                                <Card>
                                    <Card.Header className='row active-header'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="0">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-4 text-white'>What types of web applications can you build?</span>
                                                </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-minus text-white" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="1">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>Do web apps work on mobile as well?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="2">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>Do you provide SEO-friendly web solutions?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>What’s the difference between a web app, website, and SaaS solution?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="4">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>What sort of support and maintenance models do you provide?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="5">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>Are there different engagement models available with BSP Techno?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="6">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>How do you communicate with your clients and manage the project?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="6" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="7">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>What is the ideal team composition you choose for a development project?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="7" className="collapseparent">
                                        <Card.Body>
                                            But must explain to you how all this mistaken idea odenouncing pleasure and praising pain was born and will give
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {/* End Faq LOOP */}
                        </div>
                    </div>
                </div>
                <div className="circle-img" style={{ backgroundImage: "url(" + circleimg + ")" }} />
            </div>
        </section>
    );
}

export default Faqs;