import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

import img1 from '../../../assets/img/video-bg/Expert.jpg'
import img2 from '../../../assets/img/video-bg/Video-Presentation2.JPG'

const wculist = [
    { icon: 'far fa-check-circle', title: 'Tailored Solutions', subtitle: "Unlock bespoke solutions perfectly aligned with your unique business needs, ensuring unmatched success." },
    { icon: 'far fa-check-circle', title: 'Proven Industry Expertise' ,  subtitle: "Trust in our track record of success across diverse sectors, delivering excellence tailored to your industry." },
    { icon: 'far fa-check-circle', title: 'Innovation at Its Core' ,  subtitle: " Embrace cutting-edge technologies and industry best practices, keeping you ahead of the competition." },
    { icon: 'far fa-check-circle', title: 'Collaborative Approach' ,  subtitle: "Your vision is paramount; we work closely with you, fostering open communication to exceed your goals." },
    { icon: 'far fa-check-circle', title: 'Quick and Quality Support' ,  subtitle: "Experience top-notch customer service and prompt assistance, ensuring a seamless journey with us." },
    { icon: 'far fa-check-circle', title: 'Proactive Problem-Solving' ,  subtitle: "Challenges are opportunities; we anticipate and address potential hurdles, empowering your growth." },
]
function Whyus(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }


    return (
        <section className="wcu-section section-gap">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className='col-12 text-center'>
                        <div className="wcu-text-two">
                            <div className="section-title both-border mb-30">
                                <h2 className="title">Partner with BSP Technologies for Success</h2>
                                <span className="title-tag">Your Vision, Our Expertise</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-video wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="video-poster-one bg-img-c" style={{ backgroundImage: "url(" + img1 + ")" }}>
                            </div>
                            <div className="video-poster-two bg-img-c" style={{ backgroundImage: "url(" + img2 + ")" }}>
                                {/* <Link to="#" onClick={openVideoModal} className="popup-video" role="button">
                                    <i className="fas fa-play" />
                                </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <p>
                            Partner with BSP Technologies to elevate your business. Our collaborative and proactive approach, coupled with proven industry expertise, innovation, quick support, and quality services, ensures tailored solutions for your success.
                        </p>
                        <div className="wcu-text-two">
                        
                           
                            <ul className="wcu-list clearfix fa-ul">
                                {wculist.map((item, i) => (
                                    <li key={i} className="w-100">
                                        <span className='fa-li'><i className={item.icon} /></span> {item.title}
                                        <span className='wcu-span'>{item.subtitle}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row text-center justify-content-center mt-3'>
                    <span className='service-subtitle'> Unleash your vision with us, and embark on a transformative journey toward excellence. Connect with our experts today to unlock your business's true potential.</span>                    
                </div>
                <div className="view-more-btn text-center mt-30">
                    <Link to="/contact" className="main-btn main-btn-3">Unleash Your Potential</Link>
                </div>
            </div>

            <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} />
        </section>
    );
}

export default Whyus;