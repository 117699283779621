import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import webdesign from '../../../assets/img/services/UiUxDevelopment/webdesign.svg'
import mobileapp from '../../../assets/img/services/UiUxDevelopment/mobileapp.svg'
import uxconsultant from '../../../assets/img/services/UiUxDevelopment/uxconsultant.svg'
import wireframe from '../../../assets/img/services/UiUxDevelopment/wireframe.svg'

const serviceblock = [
    { icon: webdesign, title: 'Website Design', text: 'Immerse your visitors into your website interface while offering seamless functionality with our UI/UX website designing services. We integrate aesthetics and features to ensure your visitors get the full experience while browsing your website.' },
    { icon: mobileapp, title: 'Mobile App Design', text: 'Improve your mobile application’s downloads and conversion rate with intelligent UI/UX designing. We put our extensive experience to work while designing the mobile application interface with bespoke design elements for the best outcomes.' },
    { icon: uxconsultant, title: 'UX Consulting', text: 'Discover strategies and ideas that will make your digital platform propel to the next level. We provide a comprehensive evaluation of your solution and recommend result-oriented steps based on the best UX practices. Ask us to provide expert UX consultation at any stage of your product development.' },
    { icon: wireframe, title: 'Wireframe and Blueprints', text: 'Get a better idea of your digital solutions and products with our innovative wireframing and blueprinting techniques. We have the right tools and techniques to visualize your ideas and print them onto visible mediums, which also helps analyze their efficiency and accuracy.' },
]

function Service(props) {
    return (
        <section className="service-section shape-style-one section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Craft Memorable Experiences with UI/UX Designing</span>
				<h2 className="title">Launch your digital presence with attractive interfaces that empathize with customers.</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center">
                                <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                {/* <a href='javascript:void(0)' className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                        <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
            <div className="dots-line">
                <img src={lines} alt="" />
            </div>
        </section>
    );
}

export default Service;