import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
// import Breadcrumbs from '../layouts/Breadcrumbs'
// import Client from '../layouts/Client'
import Content from "../sections/JobApplication/Content";
import Jobseoscema from "../layouts/Jobseoscema";
import title from "../layouts/Title.json";
// import ReactGA from "react-ga4"

const Apply = (props) => {
  window.scrollTo(0, 0);
  const URL = window.location.href;
  return (
    <Fragment>
      <Jobseoscema propsData={{props}} />
      <Header />
      {/* <Breadcrumbs breadcrumb={{ pagename: 'About Us' }} /> */}
      <Content propsData={{props}}/>
      {/* <section className="client-section">
                <div className="container">
                    <div className="client-slider section-gap line-top">
                        <Client />
                    </div>
                </div>
            </section> */}
      <Footer />
    </Fragment>
  );
};

export default Apply;
