import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import Breadcrumbs from '../../layouts/Breadcrumbs'
import Client from '../../layouts/Client'
import Content from '../../sections/blogdetail/mostExcitingMobileApplicationDevelopmentTrendsContent'
import blogbanner from '../../../assets/img/blog/most-exciting-mobile-application-2.jpg'

class mostExcitingMobileApplicationDevelopmentTrends extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <MetaTags>
                    <title>MOST EXCITING MOBILE APPLICATION DEVELOPMENT TRENDS IN 2018 - BSP Technologies</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header />
                <Breadcrumbs breadcrumb={{ pagename: 'MOST EXCITING MOBILE APPLICATION DEVELOPMENT TRENDS IN 2018' , breadcrumbimg: blogbanner }} />
                <Content pageH1="MOST EXCITING MOBILE APPLICATION DEVELOPMENT TRENDS IN 2018" />
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap">
                            <Client />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default mostExcitingMobileApplicationDevelopmentTrends;