import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'

import processbg1 from '../../../assets/img/video-bg/01.jpg'
import processbg2 from '../../../assets/img/video-bg/02.jpg'
import appdevelop from '../../../assets/img/services/mobileDevelopment/appdevelop.svg'
import apptesting from '../../../assets/img/services/mobileDevelopment/apptesting.svg'
import launch from '../../../assets/img/services/mobileDevelopment/launch.svg'
import marketanalysis from '../../../assets/img/services/mobileDevelopment/marketanalysis.svg'
import strategyandidentification from '../../../assets/img/services/mobileDevelopment/strategyandidentification.svg'
import support from '../../../assets/img/services/mobileDevelopment/support.svg'
import uiuxdesign from '../../../assets/img/services/mobileDevelopment/uiuxdesign.svg'

const processlist = [
    { icon: 'fal fa-coffee', number: '01', title: 'Have A Coffee', text: 'Doloremque laudantium totam raperiaeaqu ipsa quae ab illo inventore veritatis etquasi' },
    { icon: 'fal fa-coffee', number: '02', title: 'Meet With Advisors', text: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiumdoloremque' },
    { icon: 'fal fa-coffee', number: '03', title: 'Achieve Your Goals', text: 'Quis autem vel eum iure reprehenderit qui ieas voluptate velit esse quam nihil mole' },
]

function Workingprocess(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }
    return (
        <section className="working-process-section process-flow-section">
            <div className="container">
                 <div className="section-title text-center both-border mb-30">
                    <span className="title-tag">Development Process Specific to Mobile Application</span>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={strategyandidentification} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Strategy and Identification</h3>
                            <p className='mt-3'>
                                The process begins with identifying the client’s requirements and building a strategy around them. We create a structure and reasoning for why a business needs a mobile application and how the customer will benefit from this particular solution. As a result, we identify the purpose, target audience, and competition and create a list of objectives and goals for development purposes. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={marketanalysis} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Market Analysis and App Planning</h3>
                            <p className='mt-3'>
                                As the application idea starts to take shape, we move into the analysis phase. Here, the development and design team will figure out the technical and functional requirements of the final product. We create a product roadmap and establish the development milestones. Here we define and visualize how the MVP will look and create a launch sequence.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={uiuxdesign} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Working on the UI/UX Design</h3>
                            <p className='mt-3'>
                            The purpose of working on the UI/UX design is to create a system for seamless user experience. The application should have a polished look, an intuitive interface, and seamless functionality. We make your end-users usage experience seamless and interactive with intelligent and stunning design. We build wireframes, mockups, and clickable prototypes in this stage. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={appdevelop} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Application Development</h3>
                            <p className='mt-3'>
                            This is the stage where the coders turn the concepts, designs, and ideas into a working solution. We start with defining the technical architecture, choosing the right technologies for development, and working according to the development milestones. We work on the back-end, front-end, APIs, databases, and servers to create a functional product.
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={apptesting} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Testing the Application</h3>
                            <p className='mt-3'>
                            With the development work complete, we move on to the testing and quality assurance phase. We ensure that your application is stable, usable, functional, performant, and secure from all ends. We create different test cases to evaluate the final product from different ends. Through this process, we fix the bugs, improve the performances, and create a final product that is unique to your business. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={launch} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Launch and Deployment</h3>
                            <p className='mt-3'>
                            In this segment, we work on the application launch and create a plan depending on the application store you wish to launch the application on. We will devise a plan starting from fulfilling the requirements to making the package and bundling information. Moreover, there are test user account submission processes as a part of the launch process we can provide for your benefit. 
                            </p>
                        </div>
                    </div>
                    <div className='col-12 text-center'>
                        <div className="arrow bounce mt-5">
                            <i className="fa fa-arrow-down fa-2x"></i>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className='col-12'>
                        <div className='row align-items-center justify-content-sm-center'>
                            <img src={support} className="img-responsive process-flow" alt=""/>
                            <h3 className='ml-4'>Maintenance and Ongoing Support</h3>
                            <p className='mt-3'>
                            We also offer complete post-development support to our clients with our continuous maintenance model. You can contact us when your employees or end-users are facing issues with the application. Also, we are ever-ready to scale the application as and when required.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="working-circle" /> */}

            {/* <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} /> */}
        </section>
    );
}

export default Workingprocess;