import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import illustration from '../../../assets/img/services/webDevelopment/faq.jpg'
import circleimg from '../../../assets/img/services/webDevelopment/faq2.JPG'
import $ from 'jquery';

function Faqs(props) {

    return (
        <section className="faq-section section-gap with-illustration with-shape grey-bg">
            <div className="container">
                <div className="faq-illustration-img">
                    <img src={illustration} alt="illustration" />
                </div>
                <div className="row justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="faq-content">
                            <div className="section-title mb-40 both-border">
                                <span className="title-tag">FAQs</span>
                            </div>
                            {/* FAQ LOOP */}
                            <Accordion defaultActiveKey="0" className="faq-loop" id="faqAccordion">
                                <Card>
                                    <Card.Header className='row active-header'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="0">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-4 text-white'>What’s the difference between UI and UX?</span>
                                                </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-minus text-white" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="collapseparent">
                                        <Card.Body>
                                        User experience refers to how the end-user uses the product. The purpose of the UX design is to ensure that the product is usable and functional. However, User Interface or UI is about how the product or solution looks. It includes the product’s outlook, color, layout, and other interactive elements.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="1">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>What’s the work of a UI/UX developer?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1" className="collapseparent">
                                        <Card.Body>
                                        UX developers are tasked with creating the product strategy and conducting user research. They help identify whether the product or solution satisfies the user’s requirements and preferences. The job of a UI designer is to work on the visuals of the solution or product. They work on solution design, typography, and design patterns.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="2">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>Does UI/UX development require coding?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2" className="collapseparent">
                                        <Card.Body>
                                        Not necessarily; the work of a UI and UX designer does not require any coding. The professionals in this industry work to create and design the interface and the layout of a product. They create mockups with digital solutions. The interaction of a designer and developer happens when the former hands off the design code and elements to the developer. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark'>How can UI/UX impact my business outcome?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                        The ultimate impact of UI/UX on a business is that it helps increase customer experience and satisfaction levels. It helps increase the number of people who use the product along with enhancing the amount of time while using the solution.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {/* End Faq LOOP */}
                        </div>
                    </div>
                </div>
                <div className="circle-img" style={{ backgroundImage: "url(" + circleimg + ")" }} />
            </div>
        </section>
    );
}

export default Faqs;