import React, { Fragment } from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
import Content from '../sections/home/Content'
import SeoDetails from '../layouts/SeoDetails'

const Home = ()=> {
    window.scrollTo(0, 0);
    return (
        <Fragment>
            <SeoDetails page="Home"/>
            <Header />
            <Content />
            <Footer />
        </Fragment>
    );
    
}
export default Home;