import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import backend_development from '../../../assets/img/icons/Backend-Development.png'
import Front_end_web_development from '../../../assets/img/icons/Front-end-Development.png'
import Mobile_App_Development from '../../../assets/img/icons/mobile-development.png'

const serviceblock = [
    { icon: backend_development, title: 'Mobile Application Development', text: 'We build mobile applications for small to enterprise-level businesses with a customized approach. We take your idea from concept to reality enabling your business to achieve unmatched performance, scalability, and revenue through mobile-ready customers. Access new revenue streams and build your brand image with innovative applications.' },
    { icon: Front_end_web_development, title: 'Cross Platform', text: 'Engage your customers with a feature-rich and aesthetically pleasing immersive interface. Cross-platform applications run on any platform by leveraging device-specific features and functions. With technologies like React Native, Xamarin, and Flutter, our developers and designers enhance your brand’s market presence by covering the target audience with intelligent apps.' },
    { icon: Mobile_App_Development, title: 'Mobile App Strategy', text: 'The right mobile strategy can take your brand from plinth to paramount at rocket speed. Sit with our mobile development experts and business strategists to understand the nuances of a good mobile app strategy and launch your solution in the market with the best features, design, and approach.' },
    { icon: backend_development, title: 'Application Design', text: 'People like to engage with an interface that looks beautiful and works smoothly. At BSP Techno, we have a special team of designers for creating conversion-ready application designs. We create designs specifically for your business and target audience while incorporating their preferences.' }
]

function Service(props) {
    return (
        <section className="service-section shape-style-one section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Creating Innovative Mobile Applications</span>
				<h2 className="title">We help businesses lead the industry with modern applications enabled with cloud technology giving them resilience and adaptability.</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center">
                                <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div>
                                <h3>{item.title}</h3>
                                <p>{item.text}</p>
                                {/* <a href='javascript:void(0)' className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </a> */}
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                        <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
            <div className="dots-line">
                <img src={lines} alt="" />
            </div>
        </section>
    );
}

export default Service;