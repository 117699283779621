import React, { useState } from "react";
import jobDetails from "../careers/JobDetails";
import { Modal, Button, ModalHeader, ModalBody } from "reactstrap";
import { ModalFooter } from "react-bootstrap";
import { store } from "../../../actions/dashboard";
import api from "../../../common/api";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";

function Jobdetails(props) {
  const URL = window.location.href;
  const [isOpen, setisOpen] = useState(false);
  var pageUrl = window.location.href;
  var pageTitle = "Home Page";
  var page_request_details = {
    pageTitle: pageTitle,
    pageUrl: pageUrl,
  };
  const SITE_KEY = "6Lf2ME4jAAAAAOUILuUZwmGbnF71_DM2Jk1pCA2i";
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFileDetails] = useState();
  const [frmData, setFrmData] = useState({
    name: "",
    mobile: "",
    email: "",
    message: "",
    page_request_details: JSON.stringify(page_request_details),
    inquiry_from: pageTitle,
    resume: selectedFile,
    reCaptcha_token: "",
    fileDetails: file,
  });

  const [errors, setError] = useState({});
  const onChangeHandler = (event) => {
    if (event.target.files != undefined) {
      let errors = {};
      let formIsValid = true;
      if (event.target.files[0].type === "application/pdf") {
        //console.log(event.target,event.target.files);
        setSelectedFile(event.target.files[0]);
        setFileDetails(event.target.files);
      } else {
        formIsValid = false;
        errors["resume"] = "Please Select PDF File.";
      }
    }
    const { name, value } = event.target;
    setFrmData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
    setError((preValue) => {
      return {
        ...preValue,
        [name]: "",
      };
    });
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          setFrmData((preValue) => {
            return {
              ...preValue,
              reCaptcha_token: token,
            };
          });
        });
    });
  };
  const handleValidSubmit = (e) => {
    //console.log(e.target.files);
    e.preventDefault();
    let errors = {};
    let formIsValid = true;
    if (!frmData.name) {
      errors["name"] = "Please enter your name.";
    }

    if (!frmData.email) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(frmData.email)
    ) {
      formIsValid = false;
      errors["email"] = "Invalid email address.";
    }
    if (!frmData.mobile) {
      formIsValid = false;
      errors["mobile"] = "Please enter your Mobile no.";
    }
    if (!frmData.resume) {
      formIsValid = false;
      errors["resume"] = "Please Upload Resume.";
    }
    setError(errors);
    if (formIsValid == true) {
      //props.store(frmData, api.CONTACT);
      console.log(frmData);
    }
  };
  return (
    <section className="service-details section-gap">
      <div className="container">
        <div className="row">
          {/* Dettails Content */}
          <div className="col-lg-12">
            <div className="service-details-content">
              {jobDetails.length > 0
                ? jobDetails.map((item, i) => {
                    if (item.slug === props.propsData.props.match.params.name) {
                      return (
                        <>
                          <div className="row align-items-center">
                            <div className="col-md-9 col-12">
                              <h2 className="title mb-0">{item.JobTitle}</h2>
                            </div>
                            <div className="col-md-3 col-12">
                              <a
                                href="javascript:void(0)"
                                className="main-btn main-btn-3"
                                onClick={() => {
                                  setisOpen(!isOpen);
                                }}
                              >
                                Apply Now
                              </a>
                            </div>
                            <div className="col-12">
                              <span className="mb-30">
                                <i className="fa fa-map-marker-alt"></i>
                                &nbsp;&nbsp;&nbsp;{item.Location}
                                ,&nbsp;&nbsp;&nbsp;
                              </span>
                              <span className="mb-30">
                                <i className="fa fa-history"></i>
                                &nbsp;&nbsp;&nbsp;{item.Experience}
                              </span>
                              <span className="mb-30">
                              &nbsp;&nbsp; No. of Opening :
                                &nbsp;&nbsp;&nbsp;{item.Opening? item.Opening: 1}
                              </span>
                            </div>
                          </div>
                          {item.JobDetails.length > 0
                            ? item.JobDetails.map((itemDetails, i) => {
                                if (itemDetails.type === "paragraph") {
                                  return (
                                    <>
                                      <p>{itemDetails.value}</p>
                                    </>
                                  );
                                }
                                if (itemDetails.type === "Title") {
                                  return (
                                    <>
                                      <h3 className="sub-title mt-30">
                                        {itemDetails.value}
                                      </h3>
                                    </>
                                  );
                                }
                                if (itemDetails.type === "List") {
                                  return (
                                    <>
                                      <ul className="check-list mt-30 mb-40">
                                        {itemDetails.value.length > 0
                                          ? itemDetails.value.map(
                                              (itemList, i) => {
                                                return (
                                                  <>
                                                    <li>
                                                      <i className="fas fa-check" />
                                                      {itemList.items}
                                                    </li>
                                                  </>
                                                );
                                              }
                                            )
                                          : null}
                                      </ul>
                                    </>
                                  );
                                }
                              })
                            : null}
                        </>
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
              <div className="row">
                <div className="col-12 text-center">
                  <a
                    href="javascript:void(0)"
                    className="main-btn main-btn-3"
                    onClick={() => {
                      setisOpen(!isOpen);
                    }}
                  >
                    Apply Now
                  </a>
                </div>
              </div>
              <Modal
                centered
                size="lg"
                style={{ maxWidth: "768px", width: "100%" }}
                isOpen={isOpen}
                toggle={() => {
                  setisOpen(!isOpen);
                }}
              >
                <ModalHeader>Share Your Resume</ModalHeader>
                <ModalBody>
                  <div className="row">
                    <div className="col-12">
                      {jobDetails.length > 0
                        ? jobDetails.map((item, i) => {
                            if (
                              item.slug ===
                              props.propsData.props.match.params.name
                            ) {
                              return (
                                <>
                                  <p>
                                    Please send your resume to{" "}
                                    <a href="mailto:hr@bsptechno.co">
                                      {" "}
                                      hr@bsptechno.com
                                    </a>{" "}
                                    to apply for the {item.JobTitle} position.
                                  </p>
                                </>
                              );
                            } else {
                              return null;
                            }
                          })
                        : null}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="row">
                    <div className="col-12 pull-right">
                      <Button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                          setisOpen(!isOpen);
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Jobdetails;
