import React, { Component } from 'react'
import PageHeader from './PageHeader';


class differenceBetweenWebsiteAndWebApplicationContent extends Component {
    render() {
        const { pageH1 } = this.props;
        return (
            <section className="blog-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />
                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">
                                <div className="post-meta">
                                    <p>
                                        A web application is a software or program which is accessible using any web browser. Its frontend is usually created using languages like HTML, CSS, Javascript, which are supported by major browsers. While the backend could use any programming stack like LAMP, MEAN, etc. Unlike mobile apps, there is no specific SDK for developing web applications. Web Applications came to prominence with the advent of Software as a Service (SaaS) movement.
                                    </p>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />PRIME REASON TO CHOOSE WEBSITE?</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Helps you to achieve your business goals</li>
                                        <li><i className="fa fa-square mr-2" />Allows you to increase your customer support</li>
                                        <li><i className="fa fa-square mr-2" />An effective method to showcase your products and services</li>
                                        <li><i className="fa fa-square mr-2" />Developing a site helps you to create your social proof</li>
                                        <li><i className="fa fa-square mr-2" />Helps you in branding your business</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />PRIME REASON TO CHOOSE  WEB APPLICATION?</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Web applications can be used on any platform: Windows, Linux, Mac… as they all support modern browsers.</li>
                                        <li><i className="fa fa-square mr-2" />Compared to desktop applications, web applications are easier to maintain by as they use the same code in the entire application. There are no compatibility issues.</li>
                                        <li><i className="fa fa-square mr-2" />Mobile App store approval not required in web applications.</li>
                                        <li><i className="fa fa-square mr-2" />Released any time and in any form. No need to remind users to update their applications.</li>
                                        <li><i className="fa fa-square mr-2" />You can access these web applications 24 hours of the day and 365 days a year from any PC.</li>
                                        <li><i className="fa fa-square mr-2" />You can either make use of the computer or your mobile device to access the required data.</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />CHARACTERISTICS OF WEBSITE</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Can be easily searched using search engines like Google.</li>
                                        <li><i className="fa fa-square mr-2" />User-friendly navigation and web design</li>
                                        <li><i className="fa fa-square mr-2" />Quality and relevant Web Content is which richly displayed.</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />CHARACTERISTICS OF WEB APPLICATION</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />Modular and loosely coupled</li>
                                        <li><i className="fa fa-square mr-2" />It is easily tested with automated tests.</li>
                                        <li><i className="fa fa-square mr-2" />Cloud-hosted and highly scalable</li>
                                        <li><i className="fa fa-square mr-2" />Mostly Cross-platform</li>
                                    </ul>
                                </div>
                               
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default differenceBetweenWebsiteAndWebApplicationContent;