import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import title from './Title.json'

export default class SeoDetails extends React.Component{
    render() {
        //get full page url on this page load 
        const url = window.location.href;
        //remove any query string from the url and # from the url
        let urlWithoutQuery = url.split('?')[0].split('#')[0];
        
        //remove last / from the url if exists
        if(urlWithoutQuery.endsWith('/')){
            urlWithoutQuery = urlWithoutQuery.slice(0, -1);
        }
        return getSEODetails(this.props.page, urlWithoutQuery);
    }
}

const getSEODetails = (page, urlWithoutQuery) => {
    const isExists = title.some((item)=>{
        return item.page === page;
    });
    let seoDetails = null;
    if(isExists){
        seoDetails = title.find((item) => item.page === page);
    }else{
        seoDetails = title.find((item) => item.page === "Home");
    }

    return (
        <MetaTags>
            <>
                <title>{seoDetails.title}</title>
                <meta
                    name="description"
                    content={seoDetails.description}
                />
                <link rel="canonical" href={urlWithoutQuery} />
            </>
        </MetaTags>
    )
}
