import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

import img1 from '../../../assets/img/video-bg/Expert.jpg'
import img2 from '../../../assets/img/video-bg/Video-Presentation2.JPG'

const wculist = [
    { icon: 'far fa-check-circle', title: 'End-to-End UI/UX services' },
    { icon: 'far fa-check-circle', title: 'Working with Advanced Technologies' },
    { icon: 'far fa-check-circle', title: 'Highly Qualified Team of Experts' },
    { icon: 'far fa-check-circle', title: 'Process-driven Output' },
    { icon: 'far fa-check-circle', title: 'Multi-Industry experience' },
    { icon: 'far fa-check-circle', title: 'Timely Delivery of Tasks' },
    { icon: 'far fa-check-circle', title: 'Pocket-Friendly Pricing Structure' },
    { icon: 'far fa-check-circle', title: 'Rigorous Analysis and Testing' },
    { icon: 'far fa-check-circle', title: 'Advanced Infrastructure' },
    { icon: 'far fa-check-circle', title: 'Customer Support Services' },
]
function Whyus(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }


    return (
        <section className="wcu-section section-gap">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className='col-12 text-center'>
                        <div className="section-title both-border mb-40">
                            <span className="title-tag">The Best UI/UX Design Company</span>
                            <h2 className="title">Offering a Mix of Experience and Expertise</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-video wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="video-poster-one bg-img-c" style={{ backgroundImage: "url(" + img1 + ")" }}>
                            </div>
                            <div className="video-poster-two bg-img-c" style={{ backgroundImage: "url(" + img2 + ")" }}>
                                <Link to="#" onClick={openVideoModal} className="popup-video" role="button">
                                    <i className="fas fa-play" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-text-two">
                            <p>We bring more than 10 years of industry experience and intellectual curiosity to the table while working on your UI/UX design. Our services help businesses stand out and outperform their competitors. </p>
                            <ul className="wcu-list clearfix">
                                {wculist.map((item, i) => (
                                    <li key={i} className="w-100">
                                        <i className={item.icon} /> {item.title}
                                    </li>
                                ))}
                            </ul>
                            <Link to="/careers" className="main-btn">Join With Us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} />
        </section>
    );
}

export default Whyus;