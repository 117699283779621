import React, { Fragment} from 'react'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer'
// import Breadcrumbs from '../layouts/Breadcrumbs'
// import Client from '../layouts/Client'
import Content from '../sections/About/Content'
import SeoDetails from '../layouts/SeoDetails'

const About =(props)=> {

    window.scrollTo(0, 0);
    return (
        <Fragment>
            <SeoDetails page="About" />
            <Header />
            {/* <Breadcrumbs breadcrumb={{ pagename: 'About Us' }} /> */}
            <Content />
            {/* <section className="client-section">
                <div className="container">
                    <div className="client-slider section-gap line-top">
                        <Client />
                    </div>
                </div>
            </section> */}
            <Footer />
        </Fragment>
    );
}

export default About;