import React from 'react';

const PageHeader = (props) => {
    return (
        <>
        <div className="col-lg-12 pt-4 pb-0">
            <div className="post-header">
                <h1>{props.pageH1}</h1>
            </div>
            
        </div>
        <div className="col-lg-12 pb-4">
            <div className="breadcrumb-text">
                    <span className="" data-animation="fadeInDown" data-delay="0.8s">
                        <span><a href="/" className='text-secondary h6'>Home</a></span> &nbsp;
                        <span className='h6'> &#62;&#62; </span> &nbsp;
                        <span><a href='/blog' className='text-secondary h6'>Blog</a></span>
                        <span className='h6'> &#62;&#62; </span> &nbsp;
                        <span className='text-secondary h6'>{props.pageH1}</span>
                    </span>
                </div>
        </div>
        </>
    );
}

export default PageHeader;