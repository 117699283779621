import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap'

import illustration from '../../../assets/img/services/webDevelopment/faq.jpg'
import circleimg from '../../../assets/img/services/webDevelopment/faq2.JPG'
import $ from 'jquery';

function Faqs(props) {

    return (
        <section className="faq-section section-gap with-illustration with-shape grey-bg">
            <div className="container">
                <div className="faq-illustration-img">
                    <img src={illustration} alt="illustration" />
                </div>
                <div className="row justify-content-lg-end justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="faq-content">
                            <div className="section-title mb-40 both-border">
                                <span className="title-tag">FAQs</span>
                            </div>
                            {/* FAQ LOOP */}
                            <Accordion defaultActiveKey="0" className="faq-loop" id="faqAccordion">
                                <Card>
                                    <Card.Header className='row active-header'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="0">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-4 text-white text-left'>What’s the difference between cross-platform and native mobile applications?</span>
                                                </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-minus text-white" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0" className="collapseparent">
                                        <Card.Body>
                                        Cross-platform applications are the ones that run on any platform, and we have to write the code for these once. However, for a native application, we have to write the code separately for different platforms. For instance, a cross-platform application will run on Android, iOS, and web devices with a single codebase. But the code for a native application must be written separately for Web, Android and iOS. 
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="1">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>What’s the time required to build an application?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1" className="collapseparent">
                                        <Card.Body>
                                        To identify the time span required to build an application, we need to understand its requirements and scope. Once we have an idea about the amount of work that needs to be done, we can provide you with an estimated time. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="2">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Do you build applications for all industries and verticals?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2" className="collapseparent">
                                        <Card.Body>
                                        Yes, we have the expertise and experience to build applications for all types of businesses and verticals. In this, we integrate business-specific features, design, and functionality to ensure that your customers engage with a unique application.
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Card.Header className='row'>
                                        <Accordion.Toggle className='col-12' as={Button} variant="link" eventKey="3">
                                            <div className='row'>
                                                <div className='col-md-10'>
                                                    <span className='float-left ml-3 text-dark text-left'>Should I go for a Native or Cross-platform Application?</span>
                                                    </div>
                                                <div className='col-md-1'>
                                                    <span className="icons">
                                                        <i className="far fa-plus text-dark" />
                                                    </span>
                                                </div>
                                            </div>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3" className="collapseparent">
                                        <Card.Body>
                                        It depends on your requirements and budget. Some businesses want to provide their customers with exceptional experiences no matter the cost, and they go for native applications. But others might want to save a ton of money and address the needs of their customers on every platform at once. Both types of development processes have their unique benefits. We can suggest the right course of action for business, make sure to get in touch. 
                                            </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            {/* End Faq LOOP */}
                        </div>
                    </div>
                </div>
                <div className="circle-img" style={{ backgroundImage: "url(" + circleimg + ")" }} />
            </div>
        </section>
    );
}

export default Faqs;