import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import img from '../../../assets/img/tab-block.jpg'
import frontend from '../../../assets/img/services/Front-end.webp'
import backend from '../../../assets/img/services/Back-end.webp'
import Databases from '../../../assets/img/services/Databases.png'
import CloudServices from '../../../assets/img/services/Cloud-Services.webp'
import JS from '../../../assets/img/technologies/frontend/JavaScript.svg'
import Adnroid from '../../../assets/img/technologies/frontend/Adnroid.svg'
import AngularJS from '../../../assets/img/technologies/frontend/AngularJS.svg'
import flutter from '../../../assets/img/technologies/frontend/flutter.svg'
import iOS from '../../../assets/img/technologies/frontend/iOS.svg'
import ReactICON from '../../../assets/img/technologies/frontend/React.svg'
import ReactNative from '../../../assets/img/technologies/frontend/ReactNative.svg'
import TypeScript from '../../../assets/img/technologies/frontend/TypeScript.svg'
import Wordpress from '../../../assets/img/technologies/frontend/Wordpress.svg'
import laravel from '../../../assets/img/technologies/backend/laravel.svg'
import DotNet from '../../../assets/img/technologies/backend/microsoft-dot-net-icon.webp'
import nodejs from '../../../assets/img/technologies/backend/nodejs.svg'
import php from '../../../assets/img/technologies/backend/php.svg'
import Firebase from '../../../assets/img/technologies/database/Firebase.svg'
import Firestore from '../../../assets/img/technologies/database/Firestore.svg'
import MySQL from '../../../assets/img/technologies/database/MySQL.svg'
import PostageSQL from '../../../assets/img/technologies/database/PostageSQL.svg'
import Redis from '../../../assets/img/technologies/database/Redis.svg'
import AWSICON from '../../../assets/img/technologies/cloud/AWS.svg'
import cloudways from '../../../assets/img/technologies/cloud/cloudways.svg'
import digitalOcean from '../../../assets/img/technologies/cloud/digital-ocean.svg'
import GoogleCloud from '../../../assets/img/technologies/cloud/Google-cloud.svg'
import heroku from '../../../assets/img/technologies/cloud/heroku.svg'



const Native = [
    { icon: Adnroid, title: 'Adnroid' },
    { icon: iOS, title: 'iOS' },
]

const CrossPlatform = [
    { icon: flutter, title: 'Flutter' },
    { icon: ReactNative, title: 'React Native' }
]

function Advancedtab(props) {
    return (
        <section className="advanced-tab section-gap">
            <div className="container">
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Mobile Application Tech Stack</span>
				<h2 className="title">BSP Techno converts your ideas into reality with the help of innovative technologies, ensuring potent outcomes.</h2>
                </div>
                {/* Tabs Buttons */}
                <div className="tab-buttons">
                    <Tab.Container defaultActiveKey="native">
                        <div className="row justify-content-center">
                            <div className='col-md-4'>
                                <Nav variant="tabs" className='d-block text-center'>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="native" className='service-page-advance-tab'>Native</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="crossPlatform" className='service-page-advance-tab'>Cross Platform</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-md-8'>
                                <Tab.Content className='service-tab-content'>
                                    <Tab.Pane eventKey="native">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>Native applications are the ones that work on Android and iOS dedicatedly. To provide your customers and users with the ultimate experience, we leverage technologies like Swift (iOS) and Kotlin (Android) to deliver client-side functionality.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Native.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="crossPlatform">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>Cross-platform applications run on any operating system. These technologies for cross-platform development include Xamarin, React Native, and Flutter, which have a write-once-run-anywhere structure.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {CrossPlatform.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                    <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
        </section>
    );
}

export default Advancedtab;