import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import Header from '../../layouts/Header'
import Footer from '../../layouts/Footer'
import Breadcrumbs from '../../layouts/Breadcrumbs'
import Client from '../../layouts/Client'
import Content from '../../sections/blogdetail/primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019Content'
import blogbanner from '../../../assets/img/blog/bsp_banners_01-01-1024x267.jpg'

class primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019 extends Component {
    render() {
        window.scrollTo(0, 0);
        return (
            <Fragment>
                <MetaTags>
                    <title>PRIME REASONS TO CHOOSE FLUTTER FOR IOS DEVELOPMENT & ANDROID DEVELOPMENT IN 2019 - BSP Technologies</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header />
                <Breadcrumbs breadcrumb={{ pagename: 'PRIME REASONS TO CHOOSE FLUTTER FOR IOS DEVELOPMENT & ANDROID DEVELOPMENT IN 2019' , breadcrumbimg: blogbanner }} />
                <Content pageH1="PRIME REASONS TO CHOOSE FLUTTER FOR IOS DEVELOPMENT & ANDROID DEVELOPMENT IN 2019"/>
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap">
                            <Client />
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        );
    }
}

export default primeReasonsToChooseFlutterForIosDevelopmentAndroidAevelopmentIn2019;