import React, { Fragment } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Client from '../layouts/Client';
import Testimonials from '../../components/sections/home/Testimonials';


const Apply = (props) => {
  window.scrollTo(0, 0);
  return (
    <Fragment>

      <Header />
      <section className="banner-section">
        
          <div>
              <div className="container">
                <div className="row">
                  <div className="col-12 p-5 mt-5 mb-5">
                    <div className="banner-content text-center pageNotFound">
                      <span className="promo-text" data-animation="fadeInDown" data-delay="0.8s">
                        Oh No!
                      </span>
                      <h1 data-animation="fadeInUp" data-delay="1s" className="text-">
                        404
                      </h1>
                      <h4 className=" pl-2 mt-2" data-animation="fadeInUp" data-delay="1s">
                        There's nothing here!
                      </h4>
                    </div>
                  </div>
                </div>
            </div>
          </div>
      </section>
      <Client />
      <Testimonials />
      <Footer />
    </Fragment>
  );
};

export default Apply;