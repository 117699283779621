import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

import img1 from '../../../assets/img/video-bg/Expert.jpg'
import img2 from '../../../assets/img/video-bg/Video-Presentation2.JPG'

const wculist = [
    { icon: 'far fa-check-circle', title: 'World-class development experience' },
    { icon: 'far fa-check-circle', title: 'Innovative approaches' },
    { icon: 'far fa-check-circle', title: 'Technology expertise' },
    { icon: 'far fa-check-circle', title: 'Advanced development infrastructure' },
    { icon: 'far fa-check-circle', title: 'Discover new business opportunities' },
    { icon: 'far fa-check-circle', title: 'Secure and seamless communication' },
    { icon: 'far fa-check-circle', title: 'Accelerated development modernization' },
    { icon: 'far fa-check-circle', title: 'Instant support and maintenance' },
    { icon: 'far fa-check-circle', title: 'Advanced and emerging technologies' },
    { icon: 'far fa-check-circle', title: 'Research-Led Development' },
]
function Whyus(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }


    return (
        <section className="wcu-section section-gap">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-video wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="video-poster-one bg-img-c" style={{ backgroundImage: "url(" + img1 + ")" }}>
                            </div>
                            <div className="video-poster-two bg-img-c" style={{ backgroundImage: "url(" + img2 + ")" }}>
                                <Link to="#" onClick={openVideoModal} className="popup-video" role="button">
                                    <i className="fas fa-play" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-text-two">
                            <div className="section-title both-border mb-40">
                                <span className="title-tag">Why Choose BSP Techno?</span>
                                <h2 className="title"></h2>
                            </div>
                            <p>At BSP Techno, we have a rich background of building innovative applications for our clients. We identify the unique aspects of your business and integrate them into the digital solution.</p>
                            <ul className="wcu-list clearfix">
                                {wculist.map((item, i) => (
                                    <li key={i} className="w-100">
                                        <i className={item.icon} /> {item.title}
                                    </li>
                                ))}
                            </ul>
                            <Link to="/careers" className="main-btn">Join With Us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} />
        </section>
    );
}

export default Whyus;