import React from 'react'
import { Link } from 'react-router-dom'

//import icon1 from '../../../assets/img/icons/01.png'
//import icon2 from '../../../assets/img/icons/02.png'
//import icon3 from '../../../assets/img/icons/03.png'
import lines from '../../../assets/img/lines/07.png'
import Web_development from '../../../assets/img/counter/WebDev.svg'
import Mobile_development from '../../../assets/img/counter/MobileDev.svg'
import E_Commerce_App_Development from '../../../assets/img/counter/ecommerce.svg'
import UI_UX_App_Development from '../../../assets/img/counter/UXUI.svg'

const serviceblock = [
    { icon: Web_development, url: '/web-development', title: 'Web Development', text: "Enhance your online presence with custom web development. We utilize the latest technologies for responsive and high-performing websites." },
    { icon: Mobile_development, url: '/mobile-development', title: 'Mobile App Development', text: "Leverage BSP Technologies' expertise in mobile app development. We craft intuitive and user-friendly apps for Android and iOS to boost engagement and loyalty." },
    { icon: E_Commerce_App_Development, url: '/e-commerce-development', title: 'Ecommerce Website Development', text: "Revolutionize your online business with BSP Technologies' ecommerce solutions. Our websites combine seamless user experiences with secure payment gateways for increased sales." },
    { icon: UI_UX_App_Development, url: '/ui-ux-development', title: 'UI/UX Development', text: "Captivate your audience with captivating UI/UX development services. From wireframing to prototyping, our designs resonate with your users and drive conversions." },
]

function Service(props) {
    return (
        <section className="service-section shape-style-one section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-30">
                    <h2 className="title">Empowering Your Vision with Cutting-Edge Solutions at BSP Technologies</h2>
                    <span className="title-tag">Custom App Development Services for Growth and Success</span>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-6 col-md-6 col-sm-12 col-12 col-tiny-12 wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="service-box text-center">
                                <div className="icon">
                                    <img src={item.icon} alt="Icon" />
                                </div>
                                <h3><Link to={item.url}>{item.title}</Link></h3>
                                <p>{item.text}</p>
                                <Link to={item.url} className="service-link">
                                    <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Ready to take your business to the next level? Discover how our custom app development services can drive growth, boost engagement, and elevate your brand.</span>
                </div>
                <div className="view-more-btn text-center mt-30">
                    <Link to="/contact" className="main-btn main-btn-3">Explore Custom Solutions</Link>
                </div>
            </div>
            <div className="dots-line">
                <img src={lines} alt="" />
            </div>
        </section>
    );
}

export default Service;