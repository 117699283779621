import
{
    STORE,
    GET_LIST,
    UPDATE,
    DELETE,
    GET_ID,
    ERROR_MSG,
    CLEAR_ERROR_MSG,
} from "./types";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';
import TagManager from 'react-gtm-module'

import DashboardDataService from "../services/bsp.service";

export const store = (data, url) => async (dispatch) =>
{
    try
    {
        const res = await DashboardDataService.create(data, url);
        if (res.data.STATUS_CODE === 400 || res.data.status_code == 400)
        {
            dispatch({
                type: ERROR_MSG,
                payload: res.data.MESSAGE,
                url: url
            });
            if(res.data.status_code == 400){
                Swal.fire({
                    title: "",
                    text: res.data.message,
                    icon: "warning",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#F37A21',
                });
            }
            return Promise.reject(res.data.MESSAGE, url);
        }
        else
        {
            dispatch({
                type: STORE,
                payload: res.data,
                url: url
            });
            if(res.data.status_code == 200){
                Swal.fire({
                    title: "Thank You",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#F37A21',
                  }).then(function () {
                      // Redirect the user
                      window.location.reload();
                    });
                const tagManagerArgs = {
                    gtmId: 'GTM-T5QQG2M'
                }
                const handleAcceptCookie = () => {
                    TagManager.initialize(tagManagerArgs);
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'Click',
                        user_id : uuidv4(),
                        page: {
                        title: res.data.page_title
                        }
                    });
                    };
            }
            return Promise.resolve(res.data, url);
        }

    } catch (err)
    {
        return Promise.reject(err);
    }
};

export const get_list = (url) => async (dispatch) =>
{
    try
    {
        const res = await DashboardDataService.getAll(url);

        dispatch({
            type: GET_LIST,
            payload: res.data,
            url: url,
        });

        return Promise.resolve(res.data.DATA, url);

    } catch (err)
    {
        console.log(err);
    }
};
export const clear_error_msg = () => ({
    type: CLEAR_ERROR_MSG
});
export const update_data = (url,id, data) => async (dispatch) =>
{
    try
    {
        const res = await DashboardDataService.update(url,id, data);
        if (res.data.STATUS_CODE === 400)
        {
            dispatch({
                type: ERROR_MSG,
                payload: res.data.MESSAGE,
                url: url
            });

            return Promise.reject(res.data.MESSAGE, url);
        }
        else
        {
            dispatch({
                type: UPDATE,
                payload: res.data,
                url: url
            });

            return Promise.resolve(res.data, url);
        }

    } catch (err)
    {
        return Promise.reject(err);
    }
};
export const get_by_id = (id, url) => async (dispatch) =>
{
    try
    {
        const res = await DashboardDataService.get(id, url);
        //console.log(res.data)
        dispatch({
            type: GET_ID,
            payload: res.data,
            url: url,
        });

        return Promise.resolve(res.data, url);

    } catch (err)
    {
        return Promise.reject(err);
    }
};
export const delete_data = (id, url) => async (dispatch) =>
{
    try
    {
        const res = await DashboardDataService.delete(id, url);

        dispatch({
            type: DELETE,
            payload: res.data,
        });
        return Promise.resolve(res.data, url);
    } catch (err)
    {
        console.log(err);
    }
};




