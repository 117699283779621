import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import background from "../../../assets/img/banner/blog.jpg";



const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    fade: false,
    dots: false,
    swipe: true,
    adaptiveHeight: true,
    responsive: [{
        breakpoint: 768,
        settings: {
            arrows: false
        }
    }],
}

function Banner(props) {
    return (
        <section className="banner-section">
            <Slider className="banner-slider" id="bannerSlider" {...settings}>

                <div>
                    <div className="single-banner" style={{ backgroundImage: `url(${background})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-10">
                                    <div className="banner-content">
                                        {/* <span className="promo-text" data-animation="fadeInDown" data-delay="0.8s">
									BSP : Makes Difference
								</span> */}
                                        <h1 data-animation="fadeInUp" data-delay="1s">
                                            Blog
                                        </h1>
                                        <h4 className="text-white pl-2 mt-2" data-animation="fadeInUp" data-delay="1s">
                                            Find trends and insights penned by subject matter experts here. Gain knowledge and understand strategies with interactive content.
                                        </h4>

                                        <span className="pt-4" data-animation="fadeInDown" data-delay="0.8s">
                                            <span><a href="/">Home</a></span> &nbsp;&nbsp; <span> &#62;&#62; </span> &nbsp;&nbsp; <span>Blog</span>
                                        </span>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="banner-shapes">
                            <div className="one" />
                            <div className="two" />
                            <div className="three" />
                            <div className="four" />
                        </div>
                    </div>
                </div>

            </Slider>
            {/* <div className="search-wrap">
                <Link to="#" className="search-icon"><i className="far fa-search" /></Link>
            </div> */}
        </section>
    );
}

export default Banner;