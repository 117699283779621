import React from 'react';
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

import img1 from '../../assets/img/client-says/good-firms.png'
import img2 from '../../assets/img/client-says/clutch.png'
import img3 from '../../assets/img/client-says/upwork.png'
import img4 from '../../assets/img/client-says/itfirms.png'
import img5 from '../../assets/img/client-says/topdevelopers.png'

const clientslide = [
	{ img: img1, text: 'GoodFirms' },
	{ img: img2, text: 'Clutch' },
	{ img: img3, text: 'UpWork' },
	{ img: img4, text: 'IT Firms' },
	{ img: img5, text: 'Top Developers' },
]

const settings = {
	infinite: true,
	slidesToShow: 4,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 2000,
	speed: 500,
	arrows: false,
	fade: false,
	dots: false,
	swipe: true,
	responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 4,
			},
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 3,
			},
		},
		{
			breakpoint: 576,
			settings: {
				slidesToShow: 2,
			},
		},
		{
			breakpoint: 400,
			settings: {
				slidesToShow: 1,
			},
		},
	],
}
const ClientSays = (props) => {
	return (

		<section className='section-gap '>
			{/* Section Title */}
			<div className="section-title text-center both-border mb-30">
				<h2 className="title ">Recognized on Leading Platforms</h2>
				<span className="title-tag">Trusted by Upwork, GoodFirms, Clutch, and More</span>
			</div>
			{/* Services Boxes */}
			<Slider className="row align-items-center justify-content-between" id="clientSlider" {...settings}>
				{clientslide.map((item, i) => (
					<div key={i} className="col">
						{/* <Link to="/" className="client-img d-block text-center">
								<img src={item.img} alt="" />
							</Link> */}
						<div className="project-desc text-center">
							<img src={item.img} alt={item.text} />
							{/* <h5 className='mt-3'>
										<Link className="client-img d-block text-center" to="/">{item.text}</Link>
									</h5> */}
						</div>
					</div>
				))}
			</Slider>
		</section>
	);
}

export default ClientSays;