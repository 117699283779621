import React, { Component, Fragment } from 'react';
import Banner from './Banner'
import About from './About'
import Advancedtab from './Advancedtab'
import Service from './Service'
import Testimonials from './Testimonials'
import Video from './Video'
import Cta from './Cta'
import ClientSays from '../../layouts/Client-says'
import Faqs from './Faqs'
import Contact from './Contact'
import Client from '../../layouts/Client'
import CounterDark from './Counter-dark'
import MissionVision from './MissionVision'
import schema from "./schema.json";

const Content =(props)=> {
    return (
            <Fragment>
                <script type="application/ld+json">
                    {JSON.stringify(schema)}
                </script>
                <Banner />
                {/* <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap line-bottom">
                            <Client />
                        </div>
                    </div>
                </section> */}
                <About />
                <CounterDark />
                <section className="client-section grey-bg">
                    <div className="container">
                        <div className="client-slider section-gap">
                            <ClientSays />
                        </div>
                    </div>
                </section>
                <MissionVision />
                <Service />
                <Cta />
                <Testimonials />
                {/* <Faqs /> */}
                <Contact />
                <section className="client-section">
                    <div className="container">
                        <div className="client-slider section-gap line-bottom">
                            <Client />
                        </div>
                    </div>
                </section>
            </Fragment>
        );
}

export default Content;