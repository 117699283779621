import React from 'react'
import { Tab, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import img from '../../../assets/img/tab-block.jpg'
import frontend from '../../../assets/img/services/Front-end.webp'
import backend from '../../../assets/img/services/Back-end.webp'
import Databases from '../../../assets/img/services/Databases.png'
import CloudServices from '../../../assets/img/services/Cloud-Services.webp'
import Elementor from '../../../assets/img/technologies/Wordpress/Elementor.jpeg'
import wooCommerce from '../../../assets/img/technologies/Wordpress/wooCommerce.png'
import laravel from '../../../assets/img/technologies/backend/laravel.svg'
import flutter from '../../../assets/img/technologies/frontend/flutter.svg'
import Adnroid from '../../../assets/img/technologies/frontend/Adnroid.svg'
import iOS from '../../../assets/img/technologies/frontend/iOS.svg'
import ReactICON from '../../../assets/img/technologies/frontend/React.svg'
import MySQL from '../../../assets/img/technologies/database/MySQL.svg'



const Wordress = [
    { icon: Elementor, title: 'Elementor' },
    { icon: wooCommerce, title: 'wooCommerce' }
]

const Magento = [
    
]
const CustomECommerce = [
    { icon: laravel, title: 'Laravel' },
    { icon: flutter, title: 'Flutter' },
    { icon: Adnroid, title: 'Adnroid' },
    { icon: iOS, title: 'iOS' },
    { icon: ReactICON, title: 'React JS' },
    { icon: MySQL, title: 'MySQL' },
]
const Sopify = [
    
]

function Advancedtab(props) {
    return (
        <section className="advanced-tab section-gap">
            <div className="container">
                <div className="section-title text-center both-border mb-30">
                <span className="title-tag">Technology Stack We Use for eCommerce Development</span>
				<h2 className="title">We sculpt your idea using innovative techniques and represent it in the real world.</h2>
                </div>
                {/* Tabs Buttons */}
                <div className="tab-buttons">
                    <Tab.Container defaultActiveKey="wordress">
                        <div className="row justify-content-center">
                            <div className='col-md-4'>
                                <Nav variant="tabs" className='d-block text-center'>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="wordress" className='service-page-advance-tab'>Wordpress</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="Magento" className='service-page-advance-tab'>Mageto</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="customECommerce" className='service-page-advance-tab'>Custom E-Commerce</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className='mb-3'>
                                        <Nav.Link eventKey="shopify" className='service-page-advance-tab'>Shopify</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className='col-md-8'>
                                <Tab.Content className='service-tab-content'>
                                    <Tab.Pane eventKey="wordress">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>WordPress makes content management a breeze, especially for self-hosted websites and eCommerce stores. We leverage WordPress’ security, themes, plugins, and in-built components, adding it with our expertise to build scalable, reliable, and friendly eCommerce platforms. </p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Wordress.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Magento">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>Magento allows the building of advanced eCommerce platforms for medium and large-scale organizations. We personalize the eCommerce platforms built with Magento to deliver versatile solutions adding them with advanced features. </p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Magento.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="customECommerce">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>For eCommerce solutions that need enterprise-level development and designing, we can provide customized eCommerce services. For this, our developers use Laravel, Flutter, ReactJs, and MySQL. </p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {CustomECommerce.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="shopify">
                                        <div className="tab-text-block">
                                            <div className='row mb-30'>
                                                <div className='col-12'>
                                                    <p>Shopify is built to solve a merchant’s daily problems and challenges. Our Shopify experts harness their expertise, knowledge, and experience to create performant online stores and run businesses smoothly.</p>
                                                </div>
                                            </div>
                                            <div className='row mb-30'>
                                                <div className="job-categories front-end-technology">
                                                    {Sopify.map((item, i) => (
                                                        <div key={i} className="single-cat wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1.1s">
                                                            <span>
                                                                <img className='img img-fluid' src={item.icon} width={80}/>
                                                            </span>
                                                            <p>{item.title}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                {/* <div className='row text-center justify-content-center mt-5'>
                    <span className='service-subtitle'>Lorem Ipsum is simply dummy text of the printing.</span>                    
                </div> */}
                <div className="view-more-btn text-center mt-30">
                        <Link to="/contact" className="main-btn main-btn-3">Talk to Export</Link>
                </div>
            </div>
        </section>
    );
}

export default Advancedtab;