import React, { Component } from 'react'
import PageHeader from './PageHeader';

class advantagesToChooseSwiftUiContent extends Component {
    render() {
        const { pageH1 } = this.props;
        return (
            <section className="blog-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <PageHeader pageH1={pageH1} />
                        <div className="col-lg-12">
                            {/* Blog Details */}
                            <div className="post-details-wrap">
                                <div className="post-content">
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />It has an interactive environment (the playground)</li>
                                        <li><i className="fa fa-square mr-2" />While the language itself has a lot of concepts and moving parts you actually can get useful things done in a very small and recognizable part of the language and delay learning many of the more complex parts until later (even much later)</li>
                                        <li><i className="fa fa-square mr-2" />It is a complete and useful language, so you aren’t stuck learning some “learning language” and then being forced to learn a new language to do “real work”</li>
                                    </ul>
                                </div>
                                <div className="post-content mt-3">
                                    <h4 className="with-check"><i className="far fa-check-circle" />That said it does also have some downsides as a beginner language:</h4>
                                    <ul>
                                        <li><i className="fa fa-square mr-2" />It is in fact a large complex language featuring lots of parts that are not worth the conceptual overhead to beginners (generic types, existential types, probably even associated types)</li>
                                        <li><i className="fa fa-square mr-2" />It is a young language, so for some things if you see a three year old tutorial it might be telling you to do things that no longer work (this is happening much less, in fact a few years ago you had to worry about the language breaking your things as it evolved, now you don’t)</li>
                                        <li><i className="fa fa-square mr-2" />It is uncommon outside of the Apple ecosystem. It may well work “just fine” on servers, but you are unlikely to actually encounter it there.</li>
                                    </ul>
                                    <p>This may seem negative, but I think it is a great choice as long as you are looking to program on iOS or macOS platforms. With or without a graphical user interface.</p>
                                    <p>If you don’t care about a GUI python and ruby are also good choices and they work well on non-Apple systems (but actually not well on iOS, you are looking at more like “stuff you can do on a laptop, desktop, or server).</p>
                                    <p>SwiftUI is very new. At the moment it is ant even released, so you will need to use beta versions of the development tools, and the iOS simulator, or load a beta OS onto your Mac and/or iPhone and/or Apple Watch.</p>
                                    <p>As a beta it has a bunch of bugs. So it will be hard to tell if you did something wrong, or if that part just doesn’t work (yet?), or if the documentation is wrong.</p>
                                    <p>Speaking of documentation, there will be a lot fewer tutorials to help you, and fewer people asking the question you are about to ask on Stack Overflow (and therefore less chance that the answer you want will be found with a simple google search).</p>
                                    <p>Oh, and the error messages SwiftUI spits out when you do something wrong are…um…very difficult to interpret.</p>
                                </div>
                               
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default advantagesToChooseSwiftUiContent;