
import
{
    STORE,
    GET_LIST,
    UPDATE,
    DELETE,
    GET_ID,
    ERROR_MSG,
    CLEAR_ERROR_MSG
} from "../actions/types";

const initialState = {
    loading: true
};
const error_msg = null;
function dashboardReducer(state = initialState, action)
{
    const { type, payload, url } = action;

    switch (type)
    {
        case STORE:
            return { ...state, store_data: payload, url: url, loading: false };
        case GET_ID:
            return { ...state, singledata: payload, url: url, loading: false };
        case GET_LIST:
            return { ...state, listData: payload, url: url };
        case ERROR_MSG:
            return { ...state, error_msg: payload, url: url };

        case UPDATE:
            return { ...state, updateData: payload, url: url };
        case CLEAR_ERROR_MSG:
            return { ...state, error_msg: null, response: null };
        case DELETE:
            return { ...state, deleteData: payload, url: url, loading: false };



        default:
            return state;
    }
};

export default dashboardReducer;