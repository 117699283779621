import React from 'react'
import { Link } from 'react-router-dom'

import line1 from '../../../assets/img/lines/12.png'
import line2 from '../../../assets/img/lines/11.png'

import img1 from "../../../assets/img/industryWeServe/gems.svg";
import img2 from "../../../assets/img/industryWeServe/realestate.svg";
import img3 from "../../../assets/img/industryWeServe/healthfitness.svg";
import img4 from "../../../assets/img/industryWeServe/textile.svg";
import img5 from "../../../assets/img/industryWeServe/pulpnpaper.svg";
import img6 from "../../../assets/img/industryWeServe/hospitality.svg";
import img7 from "../../../assets/img/industryWeServe/recycling.svg";
import img8 from "../../../assets/img/industryWeServe/foodndeals.svg";
import img9 from "../../../assets/img/industryWeServe/mentalhealth.svg";
import img10 from "../../../assets/img/industryWeServe/education.svg";
import img11 from "../../../assets/img/industryWeServe/retail.svg";
import img12 from "../../../assets/img/industryWeServe/ecommerce.svg";
import img13 from "../../../assets/img/industryWeServe/healthcare.svg";
import img14 from "../../../assets/img/industryWeServe/pharma.svg";
import img15 from "../../../assets/img/industryWeServe/importexport.svg";
import img16 from "../../../assets/img/industryWeServe/salesauto.svg";
import img17 from "../../../assets/img/industryWeServe/travel.svg";
import img18 from "../../../assets/img/industryWeServe/oilgas.svg";

const industryBlock = [
    { icon: img1, title: 'Gems & Jewellery' },
    { icon: img2, title: 'Real Estate' },
    { icon: img3, title: 'Health & Fitness' },
    { icon: img4, title: 'Textile' },
    { icon: img5, title: 'Pulp and paper' },
    { icon: img6, title: 'Hospitality' },
    { icon: img7, title: 'Recycling' },
    { icon: img8, title: 'Food & Deals' },
    { icon: img9, title: 'Mental Health' },
    { icon: img10, title: 'Education' },
    { icon: img11, title: 'Retail' },
    { icon: img12, title: 'E-Commerce' },
    { icon: img13, title: 'Health care' },
    { icon: img14, title: 'Pharmaceutical' },
    { icon: img15, title: 'Import & Export' },
    { icon: img16, title: 'Sales Automation' },
    { icon: img17, title: 'Tours & Travels' },
    { icon: img18, title: 'Oil & Gas' },
]

function Industry(props) {
    return (
        <section className="service-section shape-style-two service-line-shape section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-50">
                    <h2 className="title">Transforming Industries, One Solution at a Time</h2>
                    <span className="title-tag">Innovative Solutions Tailored to Your Sector</span>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {industryBlock.map((item, i) => (
                        <div key={i} className="col-lg-3 col-sm-4 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1000ms">
                            <div className="service-box-two text-center">
                                <div className="icon">
                                    {/* <i className={item.icon} /> */}
                                    <img src={item.icon} alt={item.title} />
                                </div>
                                <h5>{item.title}</h5>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="line-one">
                <img src={line1} alt="line-shape" />
            </div>
            <div className="line-two">
                <img src={line2} alt="line-shape" />
            </div>
        </section>
    );
}

export default Industry;