export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SUCCESS = 'SUCCESS';
export const SUCCESS_FAIL = 'SUCCESS_FAIL';
export const STORE = "STORE";
export const GET_LIST = "GET_LIST";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const GET_ID = "GET_ID";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERROR_MSG = "CLEAR_ERROR_MSG";

