import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags'
import { Helmet } from 'react-helmet';
import jobDetails from "../sections/careers/JobDetails.json";

export default class JobseoSchema extends Component {
    render() {
        const { propsData } = this.props;
        return (
            <Fragment>
                {getSEODetails(propsData.props)}
                {getSchemaOrgJSONLD(propsData.props)}
            </Fragment>
        );
    }
}
const getSEODetails = (data) => (
    <MetaTags>
        {jobDetails.length > 0 && jobDetails.map((item) => {
            if (item.slug === data.match.params.name) {
                return (
                    <Fragment key={item.slug}>
                        <title>{item.metaTitle}</title>
                        <meta name="description" content={item.metaDescription} />
                    </Fragment>
                );
            }
            return null;
        })}
    </MetaTags>
);
const getSchemaOrgJSONLD = (data) => {
    const jobDetail = jobDetails.find(item => item.slug === data.match.params.name);
    const generateJobDescriptionText = (jobDetails) => {
        return jobDetails.map(detail => {
            switch (detail.type) {
                case 'paragraph':
                    return `\n${detail.value}\n`;
                case 'Title':
                    return `\n${detail.value}\n`;
                case 'List':
                    return detail.value.map(listItem => `- ${listItem.items}`).join('\n');
                default:
                    return '';
            }
        }).join('\n');
    };

    const descriptionText = generateJobDescriptionText(jobDetail.JobDetails);

    if (jobDetail) {
        const schemaOrgJSONLD = {
            "@context": "http://schema.org",
            "@type": "JobPosting",
            "title": jobDetail.JobTitle,
            "description": descriptionText,
            "datePosted": "2024-02-19", // Adjust or dynamically set this date as needed
            "employmentType": jobDetail.JobType? jobDetail.JobType : "FULL_TIME",
            "hiringOrganization": {
                "@type": "Organization",
                "name": "BSP Technologies",
                "sameAs": "http://www.bsptechno.com",
                // Include the organization's logo URL if available
                "logo": "https://www.bsptechno.com/assets/img/apple-icon-180x180.png"
            },
            "jobLocation": {
                "@type": "Place",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "C-1005 A, Ganesh Meridian, Sarkhej - Gandhinagar Hwy, opp. Kargil Petrolpump, Sola", // Include if available
                    "addressLocality": jobDetail.Location.split(',')[0].trim(),
                    "addressRegion": "GJ", // Include if available
                    "addressCountry": "IN",
                    "postalCode": "380063" // Include if available
                }
            },
            "baseSalary": {
                "@type": "MonetaryAmount",
                "currency": "INR",
                "value": {
                  "@type": "QuantitativeValue",
                  "minValue": jobDetail.minSalary? jobDetail.minSalary: 12000,
                  "maxValue":  jobDetail.mixSalary?  jobDetail.mixSalary : 25000,
                  "unitText": "MONTH"
                },
            },
            "experienceRequirements" : {
                "@type" : "OccupationalExperienceRequirements",
                "monthsOfExperience" : jobDetail.monthsExperience? jobDetail.monthsExperience : 6
            },
            "validThrough" : jobDetail.validThrough? jobDetail.validThrough : ""
        };
        return (
            <Helmet>
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                </script>
            </Helmet>
        );
    }
    return null;
};