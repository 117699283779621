import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'

import img1 from '../../../assets/img/video-bg/Expert.jpg'
import img2 from '../../../assets/img/video-bg/Video-Presentation2.JPG'

const wculist = [
    { icon: 'far fa-check-circle', title: 'Platform expertise' },
    { icon: 'far fa-check-circle', title: 'Years of experience' },
    { icon: 'far fa-check-circle', title: 'Advanced infrastructure' },
    { icon: 'far fa-check-circle', title: 'Cost-efficient development' },
    { icon: 'far fa-check-circle', title: 'On-time project delivery' },
    { icon: 'far fa-check-circle', title: 'Agile and Secure development' },
    { icon: 'far fa-check-circle', title: 'Customized development plan' },
    { icon: 'far fa-check-circle', title: 'Versatile solutions' },
    { icon: 'far fa-check-circle', title: 'Multi-purpose eCommerce Solutions' },
    { icon: 'far fa-check-circle', title: '24/7 Online Support and Maintenance' },
]
function Whyus(props) {
    const [openVideo, setOpenVideo] = useState(false)


    const openVideoModal = () => {
        setOpenVideo(true)
    }


    return (
        <section className="wcu-section section-gap">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className='col-12 text-center'>
                        <div className="section-title both-border mb-40">
                            <span className="title-tag">Why Choose BSP Techno for eCommerce Services?</span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-video wow fadeInLeft" data-wow-duration="1500ms" data-wow-delay="400ms">
                            <div className="video-poster-one bg-img-c" style={{ backgroundImage: "url(" + img1 + ")" }}>
                            </div>
                            <div className="video-poster-two bg-img-c" style={{ backgroundImage: "url(" + img2 + ")" }}>
                                <Link to="#" onClick={openVideoModal} className="popup-video" role="button">
                                    <i className="fas fa-play" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-10">
                        <div className="wcu-text-two">
                            <p>BSP Techno works diligently to deliver the solutions benefiting your business with higher sales and better customer engagement.</p>
                            <ul className="wcu-list clearfix">
                                {wculist.map((item, i) => (
                                    <li key={i} className="w-100">
                                        <i className={item.icon} /> {item.title}
                                    </li>
                                ))}
                            </ul>
                            <Link to="/careers" className="main-btn">Join With Us</Link>
                        </div>
                    </div>
                </div>
            </div>

            <ModalVideo channel="youtube" autoplay isOpen={openVideo} videoId="fEErySYqItI" onClose={() => setOpenVideo(false)} />
        </section>
    );
}

export default Whyus;